import axios from "axios";
import { getSessionToken } from "../Authentication";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in ADD_NOTIFICATION:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const addNotificationAPI = (data) => {
    console.log("Inside addNotificationAPI", data, "AUTH TOKEN", getSessionToken());
    return axios({
        method: "post",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/notifications/create`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
        data: JSON.stringify(data),
    });
};
export default addNotificationAPI;

import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Card } from 'react-bootstrap';

// styles
import '../feature-card.scss';


const FeatureCard = (props) => {
	return (
		<div className="feature-card-wrap">
			<Card className="card-with-border" onClick={props.onClick}>
				<Card.Body>
				{/* 	{
						console.log(props.icon,"props.icon")
					} */}
					<div className="feature-icon-wrap">
						{/* <span className={}></span> */}
						<img src={props.icon} alt={props.icon} />
					</div>
					<div className="feature-title">
						<div className="text-wrap">
							<h6>{props.title}</h6>
						</div>
					</div>
				</Card.Body>
			</Card>
		</div>
	)
}

/**
	icon: Provide icon src as a image
	title: Set title of card
	onClick: handlers from firing regardless of the rendered element.
*/

FeatureCard.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func
};

FeatureCard.defaultProps = {
	icon: '',
	title: '',
	onClick: () => { },
}

export default FeatureCard
const GLOBAL_ICONS = {
  ArrowUpDown: require("./arrow-class.png"),
  Attachment: require("./attachment.png"),
  Clock: require("./clock.png"),
  Close: require("./close.png"),
  Date: require("./date.png"),
  DisableDate: require("./disable-date.png"),
  CircularBack: require("./circular-back.png"),
  Pdf: require("./pdf.png"),
  Sequence: require("./sequence.png"),
  CheckMark: require("./check-mark.png"),
  Edit: require("./edit.png"),

  SurveyIcon: require("./survey.svg").default,
  NotificationIcon: require("./notification.svg").default,
  MembersIcon: require("./members.svg").default,
  InviteIcon: require("./invite.svg").default,
  Invoice: require("./invoice.svg").default,
  GreenInvoice: require("./greenInvoice.svg").default,
};

export { GLOBAL_ICONS };

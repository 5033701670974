import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import parse from "html-react-parser";
// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend } from "../../../components";
import moment from "moment";

const PushNotificationDetails = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const [notification, setNotification] = useState();

  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/push-notification/list",
      active: false,
    },
    {
      label: "Details",
      href: "/push-notification/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    setNotification(param);
  }, []);

  const parseHtml = (data) => {
    var htmlString = data;
    var plainString = htmlString?.replace(/<[^>]+>/g, "");
    return plainString;
  };
  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Push Notification Detail</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/push-notification/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Overview"
                /*  action={
                   <Btn
                       label="Edit Sequence"
                       variant="outline-primary"
                       size={"sm"}
                   />
               } */
              />
            </div>
            <div className="screen-view-row">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap title-wrap title-underline">
                      <h5>{notification?.title}</h5>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">CREATED</p>
                      <p>
                        {moment(notification?.created_at).format(
                          "MMM D, YYYY, hh:mm a"
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">STATUS</p>
                      <p className="subtitle3">
                        {moment(notification?.schedule_date).format(
                          "MMM D, YYYY, hh:mm a"
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">NOTIFICATION FOR</p>
                      <p className="subtitle3">
                        {notification?.notification_recipient_type === "all"
                          ? "All"
                          : "Selected Users"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">NOTE</p>
                      {parseHtml(notification?.description)}
                      <p>
                        {/* The County of San Mateo, led by the Office of Emergency
                        Services, has begun to develop the County’s updated
                        Multi jurisdictional Local Hazard Mitigation Plan to
                        address threats such as earthquake, flooding, extreme
                        heat, and landslide.{" "}
                        <a href="javascript:void(0)">
                          {" "}
                          https://cmo.smcgov.org/events/march-25-2021-preparing-hazards-san-mateo-county-local-hazard-mitigation-plan-public-workshop;
                        </a>
                      </p>
                      <p>
                        <strong>
                          The County of San Mateo, led by the Office of
                          Emergency Services, has begun to develop the County’s
                          updated Multi jurisdictional Local Hazard Mitigation
                          Plan to address threats such as earthquake, flooding,
                          extreme heat, and landslide.
                        </strong>
                      </p>
                      <p>
                        The County of San Mateo, led by the Office of Emergency
                        Services, has begun to develop the County’s updated
                        Multi jurisdictional Local Hazard Mitigation Plan to
                        address threats such as earthquake, flooding, extreme
                        heat, and landslide.
                      </p>
                      <p>
                        The County of San Mateo, led by the Office of Emergency
                        Services, has begun to develop the County’s updated
                        Multi jurisdictional Local Hazard Mitigation Plan to
                        address threats such as earthquake, flooding, extreme
                        heat, and landslide. */}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PushNotificationDetails;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import fileDownload from "js-file-download";
import Spinner from "react-bootstrap/Spinner";

// framework
import { Container, Row, Col, Table, ToastContainer, Toast } from "react-bootstrap";

// styles

// components
import { Btn, Paginator, FeatureContentCard, BreadCrumb, Select, Filters } from "../../../components";

// utils
import surveyAPI from "../../../utils/helpers/API/SURVEY_API";
import { downloadSurveyCSV, downloadHealthCSV } from "../../../utils/helpers/API/DOWNLOAD_CSV";
import { redirectToLogin, removeCookies } from "../../../utils/helpers/Authentication";

const SurveyList = () => {
    const location = useLocation();

    const paramVar = location.param;

    const [surveyList, setSurveyList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [downloadToastFailAction, setDownloadToastFailAction] = useState(false);

    const breadcrumb = [
        {
            label: "Survey",
            href: "/survey/list",
            active: true,
        },
    ];

    // navigation
    const history = useHistory();

    useEffect(() => {
        // console.log("param", param)

        const fetchSurvey = async () => {
            try {
                const res = await surveyAPI();
                let _data = res.data;
                if (_data != null) {
                    if (_data.message === "Unauthorized" && _data.status === "error") {
                        removeCookies();
                        redirectToLogin();
                    } else {
                        setSurveyList(_data.serveyList);
                        setLoader(false);
                    }
                }
                console.log(res.data, "res");
            } catch (error) {
                console.log(error);
            }
        };

        fetchSurvey();
        console.log("abcd");
    }, []);

    const toggleFailedDownload = () => setDownloadToastFailAction(!downloadToastFailAction);

    const fileDownloadSurveyAction = async (surveyId) => {
        try {
            const res = await downloadSurveyCSV(surveyId);

            console.log(res.data, "file download", res.headers);
            let _data = res.data;
            let headerLine = res.headers["content-disposition"];
            console.log("headerLine", headerLine);

            let startFileNameIndex = headerLine.indexOf('"') + 1;
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

            console.log(_data, "file download", filename);

            await fileDownload(_data, filename);
        } catch (error) {
            console.log(error);
            toggleFailedDownload();
        }
    };

    // const fileDownloadHealthStatusAction = async () => {
    //   try {
    //     const res = await downloadHealthCSV();

    //     console.log(res.data, "file download", res.headers);
    //     let _data = res.data;
    //     let headerLine = res.headers["content-disposition"];

    //     let startFileNameIndex = headerLine.indexOf('"') + 1;
    //     let endFileNameIndex = headerLine.lastIndexOf('"');
    //     let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    //     await fileDownload(_data, filename);
    //   } catch (error) {
    //     console.log(error);
    //     toggleFailedDownload();
    //   }
    // };

    function spinnerLoader() {
        return (
            <div className="mt-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <React.Fragment>
            {/* <NavHeader/> */}
            <div className="screen-wrap">
                <Container>
                    <BreadCrumb data={breadcrumb}></BreadCrumb>
                    <div className="screen-view-wrap">
                        <div className="screen-view-row no-border">
                            <Row>
                                <Col lg={6} sm={12}>
                                    <div className="screen-title-wrap">
                                        <div className="text-wrap">
                                            <p className="subtitle1">List of Surveys</p>
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Download Health Status"
                      onClick={() => {
                        fileDownloadHealthStatusAction();
                      }}
                    />
                  </div>
                </Col> */}
                            </Row>
                        </div>
                        <div className="screen-view-row no-border">
                            {!loader ? (
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-wrap">
                                            <Table striped bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>Survey Name</th>
                                                        <th>Created Date</th>
                                                        <th>Number of Submits</th>
                                                        <th>Report</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {surveyList.map((item, index) => {
                                                        // console.log("Hi", surveyList.ansCount);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.survey_name}</td>
                                                                <td>{moment(item.created_at).format("MMM D, YYYY")}</td>
                                                                <td>{item.ansCount}</td>
                                                                <td>
                                                                    <div
                                                                        className="btn-wrap d-flex align-items-center"
                                                                        style={{ marginLeft: -10 }}
                                                                        onClick={() => {
                                                                            fileDownloadSurveyAction(item._id);
                                                                        }}
                                                                    >
                                                                        <Btn
                                                                            variant="link"
                                                                            icon={<span className="icon-download"></span>}
                                                                            style={"btn-icon"}
                                                                            size={"sm"}
                                                                            onClick={() => {
                                                                                fileDownloadSurveyAction(item._id);
                                                                            }}
                                                                        />
                                                                        <p className="cursor-pointer primary-dark-color">Download</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-end">
                                            <Paginator />
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                spinnerLoader()
                            )}
                        </div>
                    </div>
                    <ToastContainer position="top-end">
                        <Toast bg="danger" animation={true} autohide={true} delay={3000} show={downloadToastFailAction} onClose={() => toggleFailedDownload()}>
                            <Toast.Header>
                                <strong className="me-auto">Failed to download report </strong>
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SurveyList;

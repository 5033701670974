import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Image } from 'react-bootstrap';

// styles
import './display-cover-picture.scss';

const DisplayCoverPicture = (props) => {
	return (
		<div className={`${props.containerStyle} cover-pic-wrap`}>
			<div className="cover-img-wrap">
				<Image
					src={props.src}
					fluid={props.fluid}
					rounded={props.rounded}
					roundedCircle={props.roundedCircle}
					thumbnail={props.thumbnail}
					className={props.style}
				/>
			</div>
			{
				props.caption && (
					<div className="text-wrap subtitle-wrap mb-2">
						<p className="caption">{props.caption}</p>
					</div>
				)
			}

		</div>
	)
}

/**
	* src: source of an image
	* cpation: set caption
	* style: Manually style the image
	* containerStyle: Manually style the wrapper
	* fluid: Sets image as fluid image.
	* rounded: Sets image shape as rounded.
	* roundedCircle: Sets image shape as circle.
	* thumbnail: Sets image shape as thumbnail.
*/

DisplayCoverPicture.propTypes = {
	src: PropTypes.string,
	cpation: PropTypes.string,
	style: PropTypes.string,
	containerStyle: PropTypes.string,
	fluid: PropTypes.bool,
	rounded: PropTypes.bool,
	roundedCircle: PropTypes.bool,
	thumbnail: PropTypes.bool,
};

DisplayCoverPicture.defaultProps = {
	src: '',
	cpation: '',
	style: '',
	containerStyle: '',
	fluid: true,
	rounded: false,
	roundedCircle: false,
	thumbnail: false,
}

export default DisplayCoverPicture;
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

//component
import SurveyNext from "../SurveyNext/SurveyNext";
import CreateSurvey from "../CreateSurvey/CreateSurvey";

//utils
import { getUserDetails } from "../../../utils/helpers/Authentication";
import { getSurveyDetails } from "../../../utils/helpers/helperFunctions";

//api call
import axios from "../../../utils/axios/api";

//package
import Spinner from "react-bootstrap/Spinner";

//css
import "../EditSurvey/EditSurvey.scss";
import { setEndTimeTo2355, setStartTime } from "../../../utils/dates";
import { showToast } from "../../../redux/actions/toastActions";
import { TOAST_MESSAGES } from "../../../constants/toastMessages";
import { useDispatch, useSelector } from "react-redux";

const CloneSurvey = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const surveyId = useSelector((state) => state.serveys.surveyId);
  let pathname = location.pathname.split("/");
  let id = pathname[2];

  const [surveyName, setSurveyName] = useState("");
  //using the following state to store the startDate & endDate
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //
  const [surveyDetails, setSurveyDetails] = useState([]);

  //using the following state
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [showNextStep, setShowNextStep] = useState(false);
  const [surveyMember, setSurveyMember] = useState([]);

  //using the following useEffect to call the getSurveyById && getQuestionsBy id
  useEffect(() => {
    async function fetchData() {
      if (id) {
        let viewSurveyRes = await axios.get(`/survey?surveyId=${id}`);
        let viewQuestionsRes = await axios.get(
          `/survey/question?surveyId=${id}`
        );
        Promise.all([viewSurveyRes, viewQuestionsRes]).then((res) => {
          setSurveyDetails(res[0]);
          questionsById(res[1]?.data?.list);
        });
      }
    }
    fetchData();
  }, [location.pathname]);

  //using the following function to set All the questions based on the id
  const questionsById = (res) => {
    // console.log("res", res);
    let allQuestionsById = res.map((item, index) => {
      let allquestions = {
        id: index + 1,
        questionText: item.question_title ? item.question_title : "",
        question_type: item.question_type,
        options: item.options ? item.options : ["", ""],
        isMandatory: item.is_mandatory > 0 ? item.is_mandatory : false,
        isActive: item.is_active > 0 ? item.is_active : false,
        placeholderText: item.placeholder,
        note: item.caption ? item.caption : "",
        numericOnly: item.is_numeric > 0 ? item.is_numeric : false,
        isSaved: false,
        _id: item._id ? item._id : "",
        subQuestions: [
          {
            sliderQuestion: "",
            minValue: item.min_value > 0 ? item.min_value : 0,
            maxValue: item.max_value > 0 ? item.max_value : 100,
            leftLabel: "",
            rightLabel: "",
          },
        ],
      };
      return allquestions;
    });
    setQuestions(allQuestionsById);
    setQuestionCount(allQuestionsById.length);
  };

  //using the following function to update the serveyName
  const updateSurveyNameById = (status) => {
    if (id) {
      let oldData = surveyDetails?.data.data;

      let updateSurveyDetails = handleSurveyName(status);

      if (
        oldData.survey_name === updateSurveyDetails.survey_name &&
        oldData.start_date === updateSurveyDetails.start_date &&
        oldData.end_date === updateSurveyDetails.end_date &&
        oldData.status == status
      ) {
        return;
      } else {
        axios.put(`/survey/update?surveyId=${surveyId}`, updateSurveyDetails);
      }
    }
  };

  //using the following function to get the userDetails to update the serveyName
  const handleSurveyName = (status) => {
    return {
      username: getUserDetails(),
      survey_name: surveyName,
      status: status,
      is_active: true,
      is_pulsed_active: false,
      start_date: setStartTime(startDate),
      end_date: setEndTimeTo2355(endDate),
    };
  };

  //using the followng function to update the question based on the id
  const updateQuestionsById = (questionId) => {
    if (id) {
      questions.length > 0 &&
        questions.map((item, index) => {
          if (questionId === item.id) {
            let surveyDetais = getSurveyDetails(item, id, index);
            console.log("surveyDetais", surveyDetais);
            axios
              .put(
                `/survey/question/new/update?questionId=${item._id}`,
                surveyDetais
              )
              .then((res) => {
                if (res?.data.status === "success") {
                  dispatch(
                    showToast(
                      TOAST_MESSAGES.QUESTION_UPDATE_SUCCESS.message,
                      TOAST_MESSAGES.QUESTION_UPDATE_SUCCESS.severity
                    )
                  );
                }
              })
              .catch((error) => {
                dispatch(
                  showToast(
                    TOAST_MESSAGES.QUESTION_UPDATE_FAILURE.message,
                    TOAST_MESSAGES.QUESTION_UPDATE_FAILURE.severity
                  )
                );
              });
          }
        });
    }
  };

  const handleSaveAndProceed = () => {
    setShowNextStep(true);
  };

  const handleBackToCreateSurvey = () => {
    setShowNextStep(false);
  };

  const breadcrumb = [
    {
      label: "Survey Management",
      href: "/surveyManagement",
      active: false,
    },
    {
      label: "Clone Survey",
      href: "#",
      active: true,
    },
  ];

  return showNextStep ? (
    <SurveyNext
      title="Clone"
      surveyMember={surveyMember}
      surveyName={surveyName}
      questions={questions}
      questionCount={questionCount}
      onBack={handleBackToCreateSurvey}
      breadcrumb={breadcrumb}
      updateSurveyNameById={updateSurveyNameById}
    />
  ) : surveyDetails.data ? (
    <CreateSurvey
      title="Clone"
      surveyName={surveyName}
      setSurveyName={setSurveyName}
      //   editSurveyId={id}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      questions={questions}
      setQuestions={setQuestions}
      questionCount={questionCount}
      setQuestionCount={setQuestionCount}
      onSaveAndProceed={handleSaveAndProceed}
      user={getUserDetails()} // Pass user to CreateSurvey
      updateSurveyNameById={updateSurveyNameById}
      updateQuestionsById={updateQuestionsById}
      breadcrumb={breadcrumb}
    />
  ) : (
    <div className="loader-spinner">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CloneSurvey;

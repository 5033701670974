import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import {
  MdKeyboardArrowLeft,
  MdSearch,
  MdClose,
  MdOutlineAddCircleOutline,
} from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import { FaArrowLeftLong } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";

//api call
import axios from "../../../utils/axios/api";

import "./SurveyNext.scss";
import toastr from "../../../utils/Toastr";
import useOutsideClick from "../../../utils/CustomHooks/useOutsideClick";
import {
  setActiveTab,
  setNeedsRefresh,
} from "../../../redux/actions/surveyActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";

const SurveyNext = ({
  title,
  surveyMember,
  surveyName,
  questions,
  questionCount,
  onBack,
  breadcrumb,
  updateSurveyNameById,
  surveyCreateId,
}) => {
  //using the useLocation to get the id from the url
  const location = useLocation();
  const dispatch = useDispatch();
  const surveyId = useSelector((state) => state.serveys.surveyId);
  let id = location.pathname.split("/")[2];
  const history = useHistory();
  const closeDropDownRef = useRef();

  const [filters, setFilters] = useState({
    site: false,
    role: false,
    specialty: false,
    location: false,
  });

  const [assignedMembers, setAssignedMembers] = useState(surveyMember);

  //using the following state to store the filteredData of various filters (site, role, Specialty, workLocation)
  const [checkboxFilter, setCheckboxFilter] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  //using the following states to store initaial data of whole userList, sites, roles, speciality, workLocation
  const [userList, setUserList] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [workLocationData, setWorkLocationsData] = useState([]);

  //using the following state to store the site checkbox data in the form of Array
  const [sitecheckbox, setSiteCheckbox] = useState([]);

  //using the following state to store the Role checkbox data in the form of Array
  const [roleCheckbox, setRoleCheckbox] = useState([]);

  //using the following state to store the specialty checkbox data in the form of Array
  const [specialtyCheckbox, setSpecialtyCheckbox] = useState([]);

  //using the following state to store the work_location checkbox data in the form of Array
  const [workLocationCheckbox, setWorkLocationCheckbox] = useState([]);

  //using the following state to store the filteredData of following (searchField)
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [totalSelectedmembers, setTotalSelectedmembers] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  useOutsideClick(
    closeDropDownRef,
    () => setShowDropdown(false),
    setSearchValue
  );

  //using the following Array to combine the filtered Data of both searchMember and all the filters
  useEffect(() => {
    if (!clearAll) {
      const computedMembers =
        selectedMembers.length > 0 ||
        checkboxFilter.length > 0 ||
        assignedMembers.length > 0
          ? [
              ...(assignedMembers || []),
              ...(selectedMembers || []),
              ...(checkboxFilter || []),
            ]
          : [];

      setTotalSelectedmembers(computedMembers);
    } else {
      // If clearAll is true, reset totalSelectedMembers
      setTotalSelectedmembers([]);
      setClearAll(false); // Reset clearAll after clearing
    }
  }, [selectedMembers, checkboxFilter, assignedMembers, clearAll]);

  //using the following useEffect to handle store the listOf all members Data
  useEffect(() => {
    async function fetchUserListByStatus() {
      let response = await axios
        .post(`/user/cms/list?status=accepted`)
        .then((res) => res)
        .catch((err) => console.log(err));

      setUserList(
        response?.data?.users.filter((value) => value.is_active == true)
      );
    }
    fetchUserListByStatus();
  }, []);

  //using the following useEffect to store the listOf all sites
  useEffect(() => {
    async function fetchSitesData() {
      let response = await axios
        .get(`user/sites`)
        .then((res) => res)
        .catch((err) => console.log(err));
      setSiteData(response?.data?.data);
    }
    fetchSitesData();
  }, []);

  //using the following useEffect to store the listOf All roles
  useEffect(() => {
    async function fetchRolesData() {
      let response = await axios
        .get(`/user/cms/roles`)
        .then((res) => res)
        .catch((err) => console.log(err));
      setRolesData(response?.data?.data);
    }
    fetchRolesData();
  }, []);

  //using the following useEffect to store the listOf All speciality
  useEffect(() => {
    async function fetchSpecialityData() {
      let response = await axios
        .get(`/user/specialty`)
        .then((res) => res)
        .catch((err) => console.log(err));
      setSpecialityData(response?.data?.data);
    }
    fetchSpecialityData();
  }, []);

  //using the following useEffect to store the listOf All work_Locations
  useEffect(() => {
    async function fetchWorkLocationData() {
      let response = await axios
        .get(`user/work-location`)
        .then((res) => res)
        .catch((err) => console.log(err));
      setWorkLocationsData(response?.data?.data);
    }
    fetchWorkLocationData();
  }, []);

  //using the following useEffect to filter the memner based on the userSearch
  useEffect(() => {
    const memberFiltered =
      userList.length > 0 &&
      userList.filter((member) => {
        if (member.first_name) {
          return member.first_name
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        // return member.first_name.toLowerCase()
      });
    setFilteredMembers(memberFiltered);
  }, [searchValue]);

  //using the following useEffect to filter the roles using (site,role,specialty,workLocation);
  useEffect(() => {
    function filterDetails(listDetails, chekboxDetals, filterType) {
      return listDetails.filter((member) => {
        let dataToFilter = getFilterType(filterType, member);
        let filterDetails =
          chekboxDetals.length > 0
            ? chekboxDetals.includes(dataToFilter)
            : member;
        return filterDetails;
      });
    }

    function getFilterType(type, member) {
      let filter;
      if (type === "site") {
        filter = member?.sites?.site_name;
      } else if (type === "role") {
        filter = member?.roles?.role_name;
      } else if (type === "specialty") {
        // Assuming you want to join the specialty names into a single string
        filter = member?.specialty
          .map((item) => item.specialty_name)
          .join(", ");
      } else if (type === "workLocation") {
        filter = member?.work_location
          ?.map((item) => item.work_location_name)
          .join(", ");
      } else {
        filter = "";
      }
      return filter;
    }

    let siteFilter = filterDetails(userList, sitecheckbox, "site");
    let roleFilter =
      siteFilter && filterDetails(siteFilter, roleCheckbox, "role");
    let specialityFilter =
      roleFilter && filterDetails(roleFilter, specialtyCheckbox, "specialty");
    let workLocationFilter =
      specialityFilter &&
      filterDetails(specialityFilter, workLocationCheckbox, "workLocation");

    if (
      sitecheckbox.length > 0 ||
      roleCheckbox.length > 0 ||
      specialtyCheckbox.length > 0 ||
      workLocationCheckbox.length > 0
    ) {
      setCheckboxFilter(workLocationFilter);
    } else {
      setCheckboxFilter([]);
    }
  }, [
    userList,
    sitecheckbox,
    roleCheckbox,
    specialtyCheckbox,
    workLocationCheckbox,
  ]);

  const toggleFilter = (filter) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleAddMember = (member) => {
    setSelectedMembers((prev) => [...prev, member]);
  };

  const handleRemoveMember = (memberId) => {
    let updateAssignedMember = assignedMembers.filter(
      (item) => item._id !== memberId
    );
    let updateSelectesMember = selectedMembers.filter(
      (item) => item._id !== memberId
    );
    let updateCheckboxFilter = checkboxFilter.filter(
      (item) => item._id !== memberId
    );
    setAssignedMembers(updateAssignedMember);
    setSelectedMembers(updateSelectesMember);
    setCheckboxFilter(updateCheckboxFilter);
  };

  //using the following fucntion the handle the site checkbox data
  const handleSiteCheckbox = (e) => {
    let value = e.target.value;
    if (!sitecheckbox.includes(value)) {
      setSiteCheckbox([...sitecheckbox, value]);
    } else {
      setSiteCheckbox(sitecheckbox.filter((item) => item !== value));
    }
  };

  //using the following fucntion the handle the Role checkbox data
  const handleRoleCheckbox = (e) => {
    let value = e.target.value;
    if (!roleCheckbox.includes(value)) {
      setRoleCheckbox([...roleCheckbox, value]);
    } else {
      setRoleCheckbox(roleCheckbox.filter((item) => item !== value));
    }
  };

  //using the following fucntion the handle the specialty checkbox data
  const handleSpecialtyCheckbox = (e) => {
    let value = e.target.value;
    if (!specialtyCheckbox.includes(value)) {
      setSpecialtyCheckbox([...specialtyCheckbox, value]);
    } else {
      setSpecialtyCheckbox(specialtyCheckbox.filter((item) => item !== value));
    }
  };

  //using the following fucntion the handle the work_location checkbox data
  const handleWorkLocationCheckbox = (e) => {
    let value = e.target.value;
    if (!workLocationCheckbox.includes(value)) {
      setWorkLocationCheckbox([...workLocationCheckbox, value]);
    } else {
      setWorkLocationCheckbox(
        workLocationCheckbox.filter((item) => item !== value)
      );
    }
  };

  const handleClearAll = () => {
    setSiteCheckbox([]);
    setRoleCheckbox([]);
    setSpecialtyCheckbox([]);
    setWorkLocationCheckbox([]);
    setClearAll(true);
    setSelectedMembers([]);
    setCheckboxFilter([]);
    setAssignedMembers([]);
  };

  const handleSaveAsDraft = async () => {
    try {
      // let ids = totalSelectedmembers.map((item) => {
      //   return item._id;
      // });

      // if (ids.length > 0) {
      let ids = totalSelectedmembers.map((item) => {
        return item._id;
      });

      let finalObj = {
        survey_id: surveyId !== null ? surveyId : surveyCreateId || id,
        users_ids: ids,
      };

      if (title == "Edit") {
        axios
          .put("survey/user/update/map", finalObj)
          .then((res) => {
            console.log(res);
            toastr.success("Survey saved as draft");
          })
          .then(() => {
            dispatch(setActiveTab("draft"));
            dispatch(setNeedsRefresh(true));
            setTimeout(() => {
              history.push("/surveyManagement");
            }, 3000);
          });
      } else {
        axios
          .post("survey/user/map", finalObj)
          .then((res) => {
            console.log(res);
            toastr.success("Survey saved as draft");
          })
          .then(() => {
            dispatch(setActiveTab("draft"));
            dispatch(setNeedsRefresh(true));
            setTimeout(() => {
              history.push("/surveyManagement");
            }, 3000);
          });
      }
      updateSurveyNameById("draft");
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNotifyAll = async (usersIds) => {
    const notificationObj = {
      survey_name: surveyName,
      users_ids: usersIds,
    };

    try {
      const res = await axios.post(
        "/survey/user/notification",
        notificationObj
      );
      console.log(res);
      // toastr.success("Notification sent successfully");
    } catch (err) {
      console.error(err);
      toastr.error("Failed to send notification");
    }
  };

  const handleSubmit = async () => {
    let ids = totalSelectedmembers.map((item) => {
      return item._id;
    });

    let finalObj = {
      survey_id: surveyId !== null ? surveyId : surveyCreateId || id,
      users_ids: ids,
    };

    updateSurveyNameById("active");

    await handleNotifyAll(ids);

    if (title == "Edit") {
      axios
        .put("survey/user/update/map", finalObj)
        .then((res) => {
          console.log(res);

          toastr.success(res.data.message);
        })
        .then(() => {
          setTimeout(() => {
            window.location.href = "/surveyManagement";
          }, 3000);
        });
    } else {
      axios
        .post("survey/user/map", finalObj)
        .then((res) => {
          console.log(res);
          toastr.success(res.data.message);
        })
        .then(() => {
          setTimeout(() => {
            window.location.href = "/surveyManagement";
          }, 3000);
        });
    }
  };

  return (
    <div className="assign-survey-outer-wrap">
      <Container>
        <BreadCrumb data={breadcrumb}></BreadCrumb>
        <div className="assign-survey-wrap">
          <Row>
            <Col sm={12}>
              <div className="survey-header">
                <div className="survey-header-main">
                  <MdKeyboardArrowLeft onClick={onBack} className="back-icon" />
                  <h1>
                    Assign Survey <em>(Step 2/2)</em>
                  </h1>
                </div>
                <div className="question-count">
                  <span>No. of Questions: {questions.length}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <hr className="divider" />
          </Row>
          <Row>
            <Col sm={3}>
              <div className="filter-panel">
                <div className="filter-header">
                  <div className="filter-header-filterMain">
                    <CiFilter style={{ fontSize: "20px" }} />
                    <h5>Filter</h5>
                  </div>
                  <p
                    className="filter-header-clearTex"
                    onClick={handleClearAll}
                  >
                    Clear All
                  </p>
                </div>

                <div className="filter-section">
                  <div
                    className="filter-title"
                    onClick={() => toggleFilter("site")}
                  >
                    Site{`(${sitecheckbox && sitecheckbox.length})`}{" "}
                    {filters.site ? <AiOutlineMinus /> : <GoPlus />}
                  </div>
                  {filters.site &&
                    siteData.length > 0 &&
                    siteData.map((item, index) => {
                      return (
                        <div className="filter-options" key={index}>
                          <Form.Check
                            type="checkbox"
                            label={item.site_display_name}
                            value={item.site_name}
                            onChange={handleSiteCheckbox}
                            checked={
                              sitecheckbox.includes(item.site_name)
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="filter-section">
                  <div
                    className="filter-title"
                    onClick={() => toggleFilter("role")}
                  >
                    Role{`(${roleCheckbox && roleCheckbox.length})`}{" "}
                    {filters.role ? <AiOutlineMinus /> : <GoPlus />}
                  </div>
                  {filters.role &&
                    rolesData.length > 0 &&
                    rolesData.map((item, index) => {
                      return (
                        <div className="filter-options" key={index}>
                          <Form.Check
                            type="checkbox"
                            label={item.role_display_name}
                            value={item.role_name}
                            onChange={handleRoleCheckbox}
                            checked={
                              roleCheckbox.includes(item.role_name)
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="filter-section">
                  <div
                    className="filter-title"
                    onClick={() => toggleFilter("specialty")}
                  >
                    Primary Specialty
                    {`(${specialtyCheckbox && specialtyCheckbox.length})`}
                    {filters.specialty ? <AiOutlineMinus /> : <GoPlus />}
                  </div>
                  {filters.specialty &&
                    specialityData.length > 0 &&
                    specialityData.map((item, index) => {
                      // console.log(item)
                      return (
                        <div className="filter-options" key={index}>
                          <Form.Check
                            type="checkbox"
                            label={item.specialty_display_name}
                            value={item.specialty_name}
                            onChange={handleSpecialtyCheckbox}
                            checked={
                              specialtyCheckbox.includes(item.specialty_name)
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="filter-section">
                  <div
                    className="filter-title"
                    onClick={() => toggleFilter("location")}
                  >
                    Work Location
                    {`(${workLocationCheckbox && workLocationCheckbox.length})`}
                    {filters.location ? <AiOutlineMinus /> : <GoPlus />}
                  </div>
                  {filters.location &&
                    workLocationData.length > 0 &&
                    workLocationData.map((item, index) => {
                      return (
                        <div className="filter-options" key={index}>
                          <Form.Check
                            type="checkbox"
                            label={item.work_location_display_name}
                            value={item.work_location_name}
                            onChange={handleWorkLocationCheckbox}
                            checked={
                              workLocationCheckbox.includes(
                                item.work_location_name
                              )
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </Col>
            <Col sm={9}>
              <div className="assign-survey-content">
                <div className="assignSurvey">
                  <h3>Assign Survey:</h3>
                  <Form>
                    <Form.Group controlId="assignSurvey">
                      <div className="search-bar">
                        <MdSearch className="search-icon" />
                        <Form.Control
                          type="text"
                          placeholder="Search Members"
                          value={searchValue}
                          onChange={handleSearchChange}
                        />
                        {showDropdown && (
                          <MdClose
                            className="close-icon"
                            onClick={() => {
                              setSearchValue("");
                              setShowDropdown(false);
                            }}
                          />
                        )}

                        {showDropdown && (
                          <div className="dropdown-card" ref={closeDropDownRef}>
                            <div className="selected-members-header">
                              Selected Members ({selectedMembers.length})
                            </div>
                            <div className="members-list">
                              {filteredMembers.length > 0 &&
                                filteredMembers.map((member, index) => (
                                  <div
                                    key={index}
                                    className={`member-item ${
                                      selectedMembers?.some(
                                        (m) => m?._id === member?._id
                                      )
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <div className="member-initials">
                                      {`${member?.first_name.charAt(
                                        0
                                      )}${member?.last_name.charAt(0)}`}
                                    </div>
                                    <div className="member-details">
                                      <div className="member-name">{`${member?.first_name} ${member?.last_name}`}</div>
                                      <div className="member-email">
                                        {member.username}
                                      </div>
                                    </div>
                                    {totalSelectedmembers?.some(
                                      (m) => m._id === member._id
                                    ) ? (
                                      <div
                                        onClick={() =>
                                          handleRemoveMember(member._id)
                                        }
                                        className="member-action-added"
                                      >
                                        Added{" "}
                                        <IoCloseOutline
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="member-action-add"
                                        onClick={() => handleAddMember(member)}
                                      >
                                        <MdOutlineAddCircleOutline /> Add
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Form>
                </div>
                <div className="selected-members">
                  {totalSelectedmembers.length > 0 ? (
                    <>
                      <div className="selected-members-header">
                        Selected Members ({totalSelectedmembers.length}):
                      </div>
                      <div className="selected-members-list">
                        {totalSelectedmembers?.map((member, index) => (
                          <div key={index} className="selected-member">
                            <div className="member-initials">
                              {`${member.first_name.charAt(
                                0
                              )}${member.last_name.charAt(0)}`}
                            </div>
                            <div className="member-name">{`${member.first_name} ${member.last_name}`}</div>
                            <IoCloseOutline
                              style={{ cursor: "pointer", fontSize: "16px" }}
                              onClick={() => handleRemoveMember(member._id)}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {(roleCheckbox.length > 0 ||
                        specialtyCheckbox.length > 0 ||
                        workLocationCheckbox.length > 0 ||
                        sitecheckbox.length > 0) && (
                        <div
                          className="no-member-found"
                          style={{ margin: "2rem 0 0 7.3rem" }}
                        >
                          No members found.
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div className="footer-survey-buttons">
            <Button
              className="btnPreviousSurvey"
              variant="outline-secondary"
              onClick={onBack}
            >
              <FaArrowLeftLong />
              Previous
            </Button>
            <div className="right-buttons">
              <Button variant="outline-primary" onClick={handleSaveAsDraft}>
                Save as a Draft
              </Button>
              <Button
                variant="primary"
                onClick={() => setShowConfirmPopup(true)}
                disabled={totalSelectedmembers.length === 0}
              >
                Publish and Notify all
              </Button>
            </div>
          </div>
        </div>
      </Container>
      {showConfirmPopup && (
        <CommonPopup
          title="Publish Survey"
          mainText={
            "Are you sure you want to publish the survey and notify all users"
          }
          btnTitle="Yes, Publish"
          onClose={() => setShowConfirmPopup(false)}
          onSubmit={handleSubmit}
          nextPage={true}
          totalSelectedmembers={totalSelectedmembers}
          noOfQuestions={questions?.length || questionCount}
          noOfMembers={totalSelectedmembers?.length}
          rolesData={rolesData}
          siteData={siteData}
          specialityData={specialityData}
          workLocationsData={workLocationData}
        />
      )}
    </div>
  );
};

export default SurveyNext;

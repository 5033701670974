import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

// pages
import Login from "../screens/auth/login";
import Home from "../screens/home";
import PushNotificationList from "../screens/push-notifications/push-notification-list";
import PushNotificationAdd from "../screens/push-notifications/push-notification-add";
import PushNotificationDetails from "../screens/push-notifications/push-notification-details";
import SurveyList from "../screens/survey/survey-list";
import SignUp from "../screens/signUp";
import MembersList from "../../src/screens/MembersList/index";
import EditMemberDetails from "../screens/MembersList/EditMemberDetails";
import InviteMember from "../screens/InviteMember";
import MemberDetails from "../screens/MembersList/MemberDetails";
import SurveyManagement from "../screens/survey/SurveyManagement/SurveyManagement";
import SurveyManager from "../screens/survey/SurveyManager/SurveyManager";
import ViewSurvey from "../screens/survey/ViewSurvey/ViewSurvey";
import EditSurvey from "../screens/survey/EditSurvey/EditSurvey";
import CloneSurvey from "../screens/survey/CloneSurvey";

function AppRoutes() {
  const [isAuthenticated] = useState(true);
  return (
    <Switch>
      {/* <Route exact path="/"></Route> */}
      {/* <Route
        exact
        path="/components"
        isAuthenticated={isAuthenticated}
        component={Components}
      /> */}
      <Route
        exact
        path="/"
        isAuthenticated={isAuthenticated}
        component={Login}
      />
      <Route
        exact
        path="/home"
        isAuthenticated={isAuthenticated}
        component={Home}
      />
      <Route
        exact
        path="/createSurvey"
        isAuthenticated={isAuthenticated}
        component={SurveyManager}
      />
      <Route
        exact
        path="/viewSurvey/:id"
        isAuthenticated={isAuthenticated}
        component={ViewSurvey}
      />
      <Route
        exact
        path="/editSurvey/:id"
        isAuthenticated={isAuthenticated}
        component={EditSurvey}
      />
      <Route
        exact
        path="/cloneSurvey/:id"
        isAuthenticated={isAuthenticated}
        component={CloneSurvey}
      />
      <Route
        exact
        path="/surveyManagement"
        isAuthenticated={isAuthenticated}
        component={SurveyManagement}
      />
      <Route
        exact
        path="/member-details/:memberId"
        isAuthenticated={isAuthenticated}
        component={MemberDetails}
      />
      <Route
        exact
        path="/edit-member/:memberId"
        isAuthenticated={isAuthenticated}
        component={EditMemberDetails} // Add the route for the EditMemberDetails component
      />
      <Route
        exact
        path="/push-notification/list"
        isAuthenticated={isAuthenticated}
        component={PushNotificationList}
      />
      <Route
        exact
        path="/invitemember"
        isAuthenticated={isAuthenticated}
        component={InviteMember}
      />
      <Route
        exact
        path="/membersList"
        isAuthenticated={isAuthenticated}
        component={MembersList}
      />
      <Route
        exact
        path="/push-notification/add"
        isAuthenticated={isAuthenticated}
        component={PushNotificationAdd}
      />
      <Route
        exact
        path="/push-notification/edit/:notificationId"
        isAuthenticated={isAuthenticated}
        component={PushNotificationAdd}
      />
      <Route
        exact
        path="/push-notification/details"
        isAuthenticated={isAuthenticated}
        component={PushNotificationDetails}
      />
      <Route
        exact
        path="/invite"
        isAuthenticated={isAuthenticated}
        component={SignUp}
      />

      <Route
        exact
        path="/survey/list"
        isAuthenticated={isAuthenticated}
        component={SurveyList}
      />
      {/* <Route
        exact
        path="/change-password"
        isAuthenticated={isAuthenticated}
        component={ChangePassword}
      /> */}
    </Switch>
  );
}

export default AppRoutes;

import React, { useState } from "react";
import CreateSurvey from "../CreateSurvey/CreateSurvey";
import SurveyNext from "../SurveyNext/SurveyNext";
import { getUserDetails } from "../../../utils/helpers/Authentication";
import axios from "../../../utils/axios/api";
import { setEndTimeTo2355, setStartTime } from "../../../utils/dates";

const SurveyManager = () => {
  //startDate, endDate
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [surveyName, setSurveyName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [showNextStep, setShowNextStep] = useState(false);
  const [surveyCreateId, setSurveyCreateId] = useState("");

  const handleSaveAndProceed = () => {
    setShowNextStep(true);
  };

  const handleBackToCreateSurvey = () => {
    setShowNextStep(false);
  };

  const user = getUserDetails();

  const updateSurveyNameById = (status) => {
    let updateSurveyDetails = handleSurveyName(status);

    axios?.put(
      `/survey/update?surveyId=${surveyCreateId}`,
      updateSurveyDetails
    );
  };

  //using the following function to get the userDetails to update the serveyName
  const handleSurveyName = (status) => {
    return {
      username: getUserDetails(),
      survey_name: surveyName,
      status: status,
      is_active: true,
      is_pulsed_active: false,
      start_date: setStartTime(startDate),
      end_date: setEndTimeTo2355(endDate),
    };
  };

  const breadcrumb = [
    {
      label: "Survey Management",
      href: "/surveyManagement",
      active: false,
    },
    {
      label: "Create Survey",
      href: "#",
      active: true,
    },
  ];

  return showNextStep ? (
    <SurveyNext
      surveyMember={[]}
      surveyName={surveyName}
      questions={questions}
      questionCount={questionCount}
      onBack={handleBackToCreateSurvey}
      breadcrumb={breadcrumb}
      updateSurveyNameById={updateSurveyNameById}
      surveyCreateId={surveyCreateId}
    />
  ) : (
    <CreateSurvey
      title="Add"
      surveyName={surveyName}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      setSurveyName={setSurveyName}
      questions={questions}
      setQuestions={setQuestions}
      questionCount={questionCount}
      setQuestionCount={setQuestionCount}
      onSaveAndProceed={handleSaveAndProceed}
      user={user} // Pass user to CreateSurvey
      breadcrumb={breadcrumb}
      setSurveyCreateId={setSurveyCreateId}
      surveyCreateId={surveyCreateId}
    />
  );
};

export default SurveyManager;

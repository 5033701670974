export const surveyQuestionsValidation = (questions, expandedQuestionId) => {
  let questionErrorMsg = "";
  let optionsErrorMessage = "";
 
  let questionIds = questions.filter((question) => {
   let questionText =  question.question_title === '';
   let radioOptionText = question.question_type === "radio" && question.options.some((item) => item.option_text === ''|| item.option_text === undefined);
   let multichoiceText = question.question_type === 'checkbox' && question.options.some((item) =>item.option_text === '' || item === '')
   let sliderOptionText = question.question_type === 'slider_group' && question.options.some((item) =>
   (item.question_title === '' || item.question_title === undefined) || (item.min_value === '' || item.min_value === undefined) || (item.max_value === '' || item.max_value === undefined)|| (item.min_text === ''|| item.min_text === undefined) || (item.max_text === ''|| item.max_text === undefined)
  )
  let tabOptionsText = question.question_type === 'tab' && question.options.some((item) => item.option_text === '' || item.option_text === undefined)
   return questionText || radioOptionText || multichoiceText || sliderOptionText || tabOptionsText
  })
   .map((item) => item.id)

  return questionIds
};
import React from 'react';

// framework
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

// styles
import './button.scss';

const IconButton = (props) => {


	return (
		<Button
			variant={props.variant}
			type={props.type}
			size={props.size}
			href={props.href}
			className={` ${props.style} btn-circular btn-white btn-small btn-circular-img`}
			disabled={props.disabled}
			onClick={props.onClick}
			htmlFor={props.for}
			as={props.as}
		>
			<img src={props.src} alt="back-button" />
		</Button>
	)
}

/**
	*	type : Defines HTML button type attribute. -->   'button' | 'reset' | 'submit' | null
	*	variant : One or more button variant combinations -->   
							'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'
							'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'
	*	size : Specifies a large or small button. -->		'sm' | 'lg'
	*	href : Providing a href will render an <a> element, styled as a button.
	*	style : Provide classname as style for button
	*	for : The for attribute specifies which form element a label is bound to.
	*	as : You can use a custom element type for this component.
	*	src : Provide icon src as a image
	*	disabled : Disables the Button, preventing mouse events, even if the underlying component is an <a> element
	*	onClick : handlers from firing regardless of the rendered element.
*/

IconButton.propTypes = {
	src: PropTypes.string,
	type: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.string,
	href: PropTypes.string,
	as: PropTypes.string,
	style: PropTypes.string,
	for: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

IconButton.defaultProps = {
	src: '',
	variant: 'primary',
	size: '',
	for: '',
	as: '',
	style: '',
	href: null,
	disabled: false,
	type: 'button',
	onClick: () => { },
}

export default IconButton;
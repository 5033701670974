import { useEffect, useCallback } from "react";

const useOutsideClick = (ref, callback, setSearchValue) => {
  const handleOutsideClick = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
        if (setSearchValue) {
          setSearchValue("");
        }
      }
    },
    [callback, ref, setSearchValue]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return null;
};

export default useOutsideClick;

import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const loginApi = (props) => {
    console.log("props", props);
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/user/login`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: JSON.stringify(props),
    });
};

// eslint-disable-next-line no-undef
// export default USER_API = {
//   LOGIN: loginApi,
// };

export default loginApi;

export const TOAST_MESSAGES = {
  FILE_DOWNLOAD_SUCCESS: {
    message: "File downloaded successfully",
    severity: "success",
  },
  FILE_DOWNLOAD_FAILURE: {
    message: "The survey does not have any response",
    severity: "danger",
  },
  SURVEY_DELETE_SUCCESS: {
    message: "Survey deleted successfully",
    severity: "success",
  },
  SURVEY_DELETE_FAILURE: {
    message: "Failed to delete the survey",
    severity: "danger",
  },
  SURVEY_ACTIVATE_SUCCESS: {
    message: "Survey activated successfully",
    severity: "success",
  },
  SURVEY_ACTIVATE_FAILURE: {
    message: "Failed to activate the survey",
    severity: "danger",
  },
  SURVEY_INACTIVATE_SUCCESS: {
    message: "Survey inactivated successfully",
    severity: "success",
  },
  SURVEY_INACTIVATE_FAILURE: {
    message: "Failed to inactivate the survey",
    severity: "danger",
  },
  QUESTION_DELETE_SUCCESS: {
    message: "Question Removed Successfully",
    severity: "success",
  },
  QUESTION_DELETE_FAILURE: {
    message: "Failed to delete question",
    severity: "danger",
  },
  QUESTION_UPDATE_SUCCESS: {
    message: "Question updated Successfully",
    severity: "success",
  },
  QUESTION_UPDATE_FAILURE: {
    message: "Failed to update question",
    severity: "danger",
  },
  // Add more messages as needed
};

import axios from "axios";
import { getSessionToken } from "../Authentication";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in NOTIFICATION:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const notificationAPI = (schedule_type) => {
    return axios({
        method: "get",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/notifications/type?schedule_type=${schedule_type}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
    });
};

const getNotificationDataAPI = (id) =>
    axios({
        method: "GET",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/notifications/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
    });

const editNotificationAPI = (id, data) => {
    console.log("Inside editNotificationAPI", id, data);
    return axios({
        method: "PUT",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/notifications/update/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
        data: JSON.stringify(data),
    });
};
export { notificationAPI, getNotificationDataAPI, editNotificationAPI };

export const SHOW_TOAST = "SHOW_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

export const showToast = (message, variant) => ({
  type: SHOW_TOAST,
  payload: { message, variant },
});

export const clearToast = () => ({
  type: CLEAR_TOAST,
});

import {
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_REQUEST,
  UPDATE_SURVEY_STATUS,
  DELETE_SURVEY,
  SET_ACTIVE_TAB,
  SET_NEEDS_REFRESH,
  SET_SURVEY_ID,
} from "../actions/surveyActions";

const initialState = {
  active: [],
  inactive: [],
  draft: [],
  loading: false,
  activeTab: "active",
  needsRefresh: true,
  surveyId: null,
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SURVEYS_REQUEST:
      return {
        ...state,
        loading: true,
        needsRefresh: false,
      };

    case FETCH_SURVEYS_SUCCESS:
      return {
        ...state,
        active: action.payload.active,
        inactive: action.payload.inactive,
        draft: action.payload.draft,
        loading: false,
        needsRefresh: false,
      };

    case UPDATE_SURVEY_STATUS: {
      const { selectedSurveyId, targetTab } = action.payload;

      // Find the current survey from the existing tabs
      const currentSurvey =
        state.active.find((s) => s._id === selectedSurveyId) ||
        state.inactive.find((s) => s._id === selectedSurveyId) ||
        state.draft.find((s) => s._id === selectedSurveyId);

      // Remove the survey from all tabs
      const updatedState = {
        ...state,
        active: state.active.filter((s) => s._id !== selectedSurveyId),
        inactive: state.inactive.filter((s) => s._id !== selectedSurveyId),
        draft: state.draft.filter((s) => s._id !== selectedSurveyId),
      };

      // Add the survey to the target tab at the top if found
      if (currentSurvey) {
        updatedState[targetTab] = [
          { ...currentSurvey, status: targetTab },
          ...updatedState[targetTab],
        ];
      }

      return updatedState;
    }

    case DELETE_SURVEY:
      return {
        ...state,
        active: state.active.filter((survey) => survey._id !== action.payload),
        inactive: state.inactive.filter(
          (survey) => survey._id !== action.payload
        ),
        draft: state.draft.filter((survey) => survey._id !== action.payload),
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
        // needsRefresh: true,
        // loading: false,
      };
    case SET_NEEDS_REFRESH:
      return {
        ...state,
        needsRefresh: action.payload,
      };

    case SET_SURVEY_ID:
      return {
        ...state,
        surveyId: action.payload,
      };
    default:
      return state;
  }
};

export default surveyReducer;

import React, { useEffect, useState } from "react";
import "../../screens/survey/SurveyPopup/SurveyPopup.scss";

const CommonPopup = ({
  title,
  mainText,
  btnTitle,
  onClose,
  onSubmit,
  deleteLoading,
  nextPage,
  totalSelectedmembers,
  noOfQuestions,
  noOfMembers,
  rolesData,
  siteData,
  specialityData,
  workLocationsData,
}) => {
  const [roles, setRoles] = useState([]);
  const [sites, setSites] = useState([]);
  const [specialty, setSpecialty] = useState([]);
  const [workLocation, setWorkLocation] = useState([]);

  useEffect(() => {
    if (totalSelectedmembers) {
      const extractedRoles = [];
      const extractedSites = [];
      const extractedSpecialty = [];
      const extractedWorkLocation = [];

      totalSelectedmembers.forEach((member) => {
        // Handle roles as an object or array
        if (member.roles && rolesData) {
          if (Array.isArray(member.roles)) {
            member.roles.forEach((roleId) => {
              const roleName = rolesData.find((role) => role._id === roleId);
              if (roleName) extractedRoles.push(roleName.role_display_name);
            });
          } else {
            const roleName = rolesData.find(
              (role) => role._id === member.roles._id
            );
            if (roleName) extractedRoles.push(roleName.role_display_name);
          }
        }

        // Handle sites as an object or array
        if (member.sites && siteData) {
          if (Array.isArray(member.sites)) {
            member.sites.forEach((siteId) => {
              const siteName = siteData.find((site) => site._id === siteId);
              if (siteName) extractedSites.push(siteName.site_display_name);
            });
          } else {
            const siteName = siteData.find(
              (site) => site._id === member.sites._id
            );
            if (siteName) extractedSites.push(siteName.site_display_name);
          }
        }

        if (member.specialty && Array.isArray(member.specialty)) {
          member.specialty.forEach((spec) => {
            // Check if it's an object with a display name
            if (typeof spec === "object" && spec.specialty_display_name) {
              extractedSpecialty.push(spec.specialty_display_name);
            }
            // If it's a string, use it directly
            else if (typeof spec === "string") {
              extractedSpecialty.push(spec);
            }
          });
        }

        // Handle work locations as an array of objects or strings (if needed)
        if (member.work_location && Array.isArray(member.work_location)) {
          member.work_location.forEach((loc) => {
            // Check if it's an object with a display name
            if (typeof loc === "object" && loc.work_location_display_name) {
              extractedWorkLocation.push(loc.work_location_display_name);
            }
            // If it's a string (though it seems to be always an object for now)
            else if (typeof loc === "string") {
              extractedWorkLocation.push(loc);
            }
          });
        }
      });

      // Update state with unique values
      setRoles([...new Set(extractedRoles)]);
      setSites([...new Set(extractedSites)]);
      setSpecialty([...new Set(extractedSpecialty)]);
      setWorkLocation([...new Set(extractedWorkLocation)]);
    }
  }, [
    totalSelectedmembers,
    rolesData,
    siteData,
    specialityData,
    workLocationsData,
  ]);

  return (
    <div className="popup">
      <div
        className="popup-content"
        style={{
          width: nextPage ? "auto" : "300px",
          maxWidth: nextPage ? "450px" : "none",
        }}
      >
        <div className="popup-header">
          <h2>{title}</h2>
        </div>
        <p>{mainText}</p>

        {nextPage && (
          <div className="popup-details">
            <div className="popup-info-grid">
              {/* Displaying member and question count */}
              <PopupInfoItem label="No. of Members" value={noOfMembers} />
              <PopupInfoItem label="No. of Questions" value={noOfQuestions} />

              {/* Displaying roles, sites, and locations */}
              <PopupInfoItem label="Roles" value={roles.join(", ")} />
              <PopupInfoItem label="Sites" value={sites.join(", ")} />
              <PopupInfoItem
                label="Primary Locations"
                value={specialty.join(", ")}
              />
              <PopupInfoItem
                label="Work Locations"
                value={workLocation.join(", ")}
              />
            </div>
          </div>
        )}

        <div className="popup-buttons">
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button
            className="confirm-btn"
            onClick={onSubmit}
            disabled={deleteLoading}
          >
            {deleteLoading ? "Deleting..." : btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

// Helper component for rendering label-value pairs
const PopupInfoItem = ({ label, value }) => (
  <div className="popup-info-item">
    <strong>{label}:</strong>{" "}
    {value ? (
      <span className="popup-info-value">{value}</span>
    ) : (
      <h1 style={{ fontWeight: "bold" }}>-</h1>
    )}
  </div>
);

export default CommonPopup;

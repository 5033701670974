import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

// component
import SurveyNext from "../SurveyNext/SurveyNext";
import CreateSurvey from "../CreateSurvey/CreateSurvey";
import SurveyPopup from "../SurveyPopup/SurveyPopup";

// utils
import { getUserDetails } from "../../../utils/helpers/Authentication";

// api call
import axios from "../../../utils/axios/api";

// package
import Spinner from "react-bootstrap/Spinner";

// css
import "./ViewSurvey.scss";
import { showToast } from "../../../redux/actions/toastActions";
import { useDispatch } from "react-redux";
import { TOAST_MESSAGES } from "../../../constants/toastMessages";
import { setEndTimeTo2355 } from "../../../utils/dates";
import {
  setActiveTab,
  setNeedsRefresh,
} from "../../../redux/actions/surveyActions";
import { fileDownloadSurveyAction } from "../../../utils/helpers/surveyReportDownload";

const ViewSurvey = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let pathname = location.pathname.split("/");
  let id = pathname[2];

  const [surveyName, setSurveyName] = useState("");
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [answerCount, setAnswerCount] = useState(0);
  const [showNextStep, setShowNextStep] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [actionType, setActionType] = useState(""); // New state for action type
  const [isActiveSurvey, setIsActiveSurvey] = useState(false); // State for active/inactive status
  const [surveyMember, setSurveyMember] = useState([]);
  const [surveyStatus, setSurveyStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      let viewSurveyRes = await axios.get(`/survey?surveyId=${id}`);
      let viewQuestionsRes = await axios.get(`/survey/question?surveyId=${id}`);
      Promise.all([viewSurveyRes, viewQuestionsRes]).then((res) => {
        setSurveyDetails(res[0]);
        setSurveyName(res[0]?.data?.data?.survey_name);
        setIsActiveSurvey(res[0]?.data?.data?.status === "active"); // Update active status
        questionsById(res[1]?.data?.list);
        setSurveyMember(res[0]?.data?.data?.members);
        setSurveyStatus(res[0]?.data?.data.status);
      });
    }
    fetchData();
  }, [location.pathname]);

  const questionsById = (res) => {
    let allQuestionsById = res.map((item, index) => {
      let allquestions = {
        id: index + 1,
        questionText: item.question_title ? item.question_title : "",
        question_type: item.question_type,
        options: item.options ? item.options : ["", ""],
        isMandatory: item.is_mandatory > 0 ? item.is_mandatory : false,
        isActive: item.is_active > 0 ? item.is_active : false,
        placeholderText: item.placeholder,
        note: item.caption ? item.caption : "",
        numericOnly: item.is_numeric > 0 ? item.is_numeric : false,
        isSaved: false,
        _id: item._id > 0 ? item._id : null,
        subQuestions: [
          {
            sliderQuestion: "",
            minValue: item.min_value > 0 ? item.min_value : 0,
            maxValue: item.max_value > 0 ? item.max_value : 100,
            leftLabel: "",
            rightLabel: "",
          },
        ],
      };
      return allquestions;
    });
    setQuestions(allQuestionsById);
    setQuestionCount(allQuestionsById.length);
  };

  const handleSaveAndProceed = () => {
    setShowNextStep(true);
  };

  const handleBackToCreateSurvey = () => {
    setShowNextStep(false);
  };

  const handleInActivate = () => {
    dispatch(setActiveTab("inactive"));
    dispatch(setNeedsRefresh(true));
    setActionType("inactive");
    setShowPopup(true);
  };

  const handleActivate = () => {
    dispatch(setActiveTab("active"));
    dispatch(setNeedsRefresh(true));
    setActionType("active");
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePopupSubmit = async () => {
    const body = {
      username: getUserDetails(),
      is_active: true,
      is_pulsed_active: true,
      survey_name: surveyName,
      status: actionType === "inactive" ? "inactive" : "active", // Conditionally set the status
      start_date: new Date(surveyDetails?.data?.data.start_date),
      end_date: new Date(surveyDetails?.data?.data.end_date),
    };
    try {
      let response = await axios
        .put(`survey/update?surveyId=${id}`, body)
        .then((res) => {
          setIsActiveSurvey(actionType !== "inactive"); // Update the active status based on response
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
      if (response.data.status === "success") {
        dispatch(
          showToast(
            actionType === "inactive"
              ? TOAST_MESSAGES.SURVEY_INACTIVATE_SUCCESS.message
              : TOAST_MESSAGES.SURVEY_ACTIVATE_SUCCESS.message,
            TOAST_MESSAGES.SURVEY_ACTIVATE_SUCCESS.severity
          )
        );
        // setTimeout(() => {
        history.push("/surveyManagement");
        // }, 2000);
      }
      setShowPopup(false);
    } catch (error) {
      dispatch(
        showToast(
          actionType === "inactive"
            ? TOAST_MESSAGES.SURVEY_INACTIVATE_FAILURE.message
            : TOAST_MESSAGES.SURVEY_ACTIVATE_FAILURE.message,
          TOAST_MESSAGES.SURVEY_ACTIVATE_FAILURE.severity
        )
      );
    }
  };

  const handleEdit = () => {
    history.push(`/editSurvey/${id}`);
  };

  const handleClone = () => {
    history.push(`/cloneSurvey/${id}`);
  };

  const handleFileDownload = () => {
    fileDownloadSurveyAction(id, dispatch);
  };

  const handleDeleteSurvey = async () => {
    // setDeleteLoading(true);
    let url = `/survey/update?surveyId=${id}`;
    let body = {
      username: getUserDetails(),
      is_active: false,
      is_pulsed_active: true,
      survey_name: surveyName,
      status: "draft",
      start_date: new Date(surveyDetails?.data?.data.start_date),
      end_date: new Date(surveyDetails?.data?.data.end_date),
    };

    try {
      const response = await axios.put(url, body);
      if (response.data.status === "success") {
        dispatch(setActiveTab("draft"));
        history.push("/surveyManagement");
        dispatch(setNeedsRefresh(true));
        dispatch(
          showToast(
            TOAST_MESSAGES.SURVEY_DELETE_SUCCESS.message,
            TOAST_MESSAGES.SURVEY_DELETE_SUCCESS.severity
          )
        );
      }
    } catch (error) {
      dispatch(
        showToast(
          TOAST_MESSAGES.SURVEY_DELETE_FAILURE.message,
          TOAST_MESSAGES.SURVEY_DELETE_FAILURE.severity
        )
      );
    }
  };

  const breadcrumb = [
    {
      label: "Survey Management",
      href: "/surveyManagement",
      active: false,
    },
    {
      label: "View Survey",
      href: "#",
      active: true,
    },
  ];

  return (
    <>
      {showNextStep ? (
        <SurveyNext
          surveyName={surveyName}
          questions={questions}
          questionCount={questionCount}
          onBack={handleBackToCreateSurvey}
          breadcrumb={breadcrumb}
        />
      ) : surveyDetails?.data ? (
        <CreateSurvey
          title="View"
          surveyName={surveyName}
          setSurveyName={setSurveyName}
          startDate={new Date(surveyDetails?.data?.data.start_date)}
          // setStartDate={setStartDate}
          endDate={new Date(surveyDetails?.data?.data.end_date)}
          // setEndDate={setEndDate}
          questions={questions}
          setQuestions={setQuestions}
          questionCount={questionCount}
          setQuestionCount={setQuestionCount}
          onSaveAndProceed={handleSaveAndProceed}
          user={getUserDetails()} // Pass user to CreateSurvey
          answerCount={answerCount}
          setAnswerCount={setAnswerCount}
          handleInactive={handleInActivate}
          handleActivate={handleActivate}
          handlePopupClose={handlePopupClose}
          handlePopupSubmit={handlePopupSubmit}
          handleEdit={handleEdit}
          isActiveSurvey={isActiveSurvey} // Pass the active status
          breadcrumb={breadcrumb}
          surveyMember={surveyMember}
          surveyStatus={surveyStatus}
          handleClone={handleClone}
          handleFileDownload={handleFileDownload}
          handleDeleteSurvey={handleDeleteSurvey}
        />
      ) : (
        <div className="loader-spinner">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {showPopup && (
        <SurveyPopup
          type={actionType}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
        />
      )}
    </>
  );
};

export default ViewSurvey;

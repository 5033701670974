import { getUserDetails } from "./Authentication";

export const getSurveyDetails = (question, id, index) => {
  const subquestions = getSubQuestionsOptions(question);
  //   console.log("subquestions",subquestions)
  let surveyDetails = getDetailsOnInputType(question, id, index);
  surveyDetails = Array.isArray(subquestions)
    ? { ...surveyDetails, options: subquestions }
    : { ...surveyDetails, ...subquestions };

  return surveyDetails;
};

const getDetailsOnInputType = (question, id) => {
  return {
    username: getUserDetails(),
    surveyId: id,
    question_title: question.questionText,
    question_type: question.question_type,
    order: question.order,
    ans_code: "ans_code",
    is_active: question.isActive,
    is_pulsed: false,
    is_mandatory: question.isMandatory,
    is_numeric: question.numericOnly,
    _id: question._id,
    caption: question.note,
  };
};

const getSubQuestionsOptions = (questions) => {
  //   console.log("questions", questions);
  let textSubquestions = {
    min_value: "",
    max_value: "",
    keyboard_type: "default",
    caption: questions.note,
    min_text: "",
    max_text: "",
    placeholder: questions.placeholderText ? questions.placeholderText : "",
  };
  if (questions.question_type === "slider_group") {
    let options =
      questions.options.length > 0
        ? questions.options.map((item) => {
            return {
              _id: item._id,
              question_title: item.question_title,
              order: item.order,
              ans_code: item.ans_code,
              is_active: item.is_active,
              is_pulsed: false,
              is_mandatory: item.is_mandatory,
              min_value: item.min_value,
              max_value: item.max_value,
              min_text: item.min_text,
              max_text: item.max_text,
            };
          })
        : [];
    return options;
  } else if (questions.question_type === "text") {
    return textSubquestions;
  } else if (
    questions.question_type === "radio" ||
    questions.question_type === "checkbox" ||
    questions.question_type === "tab"
  ) {
    let options =
      questions.options.length > 0
        ? questions.options.map((item, index) => {
            return {
              _id: item._id,
              option_text: item.option_text,
              option_value: item.option_text,
              image: item.image,
              order: index + 1,
              is_active: item.is_active,
            };
          })
        : [];
    return options;
  } else if (questions.questionType === "calender") {
    return {
      ...textSubquestions,
      parent_question_id: questions?.parent_question_id
        ? questions?.parent_question_id
        : null,
    };
  }
};

export const mapQuestionToPayload = (question, index, surveyId, user) => {
  const commonPayload = {
    id: question.id,
    username: user,
    surveyId: surveyId,
    question_title: question.questionText,
    order: question.order,
    is_active: question.isActive,
    is_pulsed: false,
    is_mandatory: question.isMandatory,
    caption: question.note,
    note: question.note,
  };

  if (question.question_type === "text") {
    return {
      ...commonPayload,
      question_type: "text",
      ans_code: "ans_code",
      is_numeric: question.numericOnly,
      min_value: 0,
      max_value: 999,
      keyboard_type: "default",
      min_text: "",
      max_text: "",
      placeholder: question.placeholderText,
    };
  } else if (question.question_type === "slider_group") {
    return {
      ...commonPayload,
      question_type: "slider_group",
      ans_code: "ans_code_6",
      options: question.options.map((subQuestion, subIndex) => ({
        _id: 0,
        question_title: subQuestion.question_title,
        order: subIndex + 1,
        ans_code: `ans_code_${index + 7 + subIndex}`,
        is_active: question.isActive,
        is_pulsed: false,
        is_mandatory: question.isMandatory,
        min_value: subQuestion.min_value,
        max_value: subQuestion.max_value,
        min_text: subQuestion.min_text,
        max_text: subQuestion.max_text,
      })),
    };
  } else if (question.question_type === "radio") {
    return {
      ...commonPayload,
      question_type: "radio",
      ans_code: "ans_code",
      options: question.options.map((option, idx) => ({
        option_text: option.option_text,
        option_value: option.option_text,
        image: "",
        order: idx + 1,
        is_active: option.is_active,
      })),
    };
  } else if (question.question_type === "checkbox") {
    return {
      ...commonPayload,
      question_type: "checkbox",
      ans_code: "ans_code",
      options: question.options.map((option, idx) => ({
        option_text: option.option_text,
        option_value: option.option_text,
        image: "",
        order: idx + 1,
        is_active: option.is_active,
      })),
    };
  } else if (question.question_type === "tab") {
    return {
      ...commonPayload,
      question_type: "tab",
      ans_code: "ans_code",
      options: question.options.map((option, idx) => ({
        option_text: option.option_text,
        option_value: option.option_text,
        image: "",
        order: idx + 1,
        is_active: true,
      })),
    };
  } else if (question.question_type === "calendar") {
    return {
      ...commonPayload,
      question_type: "calendar",
      ans_code: "ans_code",
      min_value: 0,
      max_value: 0,
      is_numeric: false,
      parent_question_id: "null",
      keyboard_type: "default",
      placeholder: question.placeholderText,
      isSelected: true,
      min_text: "",
      max_text: "",
    };
  }
  return null;
};

import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import { MdKeyboardArrowLeft, MdOutlineMail } from "react-icons/md";
import "./MemberDetails.scss";
import acceptedIcon from "../../../assets/images/acceptedIcon.svg";
import rejectedIcon from "../../../assets/images/rejectedIcon.svg";
import { PiPencilSimpleLineThin } from "react-icons/pi";
import { FiUserX } from "react-icons/fi";
import axios from "../../../utils/axios/api";
import MemberPopup from "../MemberPopup";

const MemberDetails = () => {
  const { memberId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [member, setMember] = useState(location.state?.member);
  const [popupType, setPopupType] = useState("");
  const [selectedMember, setSelectedMember] = useState(member);

  useEffect(() => {
    if (!member) {
      const fetchMember = async () => {
        try {
          const response = await axios.get(`user/cms/${memberId}`);
          setMember(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchMember();
    }
  }, [member, memberId]);

  const breadcrumb = [
    {
      label: "Members",
      href: "/membersList",
      active: false,
    },
    {
      label: "Member Details",
      href: "#",
      active: true,
    },
  ];

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0]?.toUpperCase())
      .join("");
  };

  const renderStatusButtons = () => {
    if (member.status === "accepted") {
      return (
        <div className="member-action-buttons">
          <div className="accepted-action-buttons">
            <Button
              variant="outline-primary"
              className="edit-button"
              onClick={() => handleEdit(member)}
            >
              <PiPencilSimpleLineThin /> Edit
            </Button>
            <Button
              className={member.isActive ? "disable-button" : "enable-button"}
              onClick={() => handleDisableClick(member)}
            >
              <FiUserX />
              {member.isActive ? "Disable Member" : "Enable Member"}
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleEdit = (member) => {
    history.push({
      pathname: `/edit-member/${member.id}`,
      state: { member },
    });
  };

  const handleAcceptClick = () => {
    setPopupType("accept");
    setSelectedMember(member);
  };

  const handleRejectClick = () => {
    setPopupType("reject");
    setSelectedMember(member);
  };

  const handleDisableClick = () => {
    setPopupType(member.isActive ? "disable" : "enable");
    setSelectedMember(member);
  };

  const handleClosePopup = () => {
    setPopupType("");
    setSelectedMember(null);
  };

  const handleSubmitPopup = async (reason) => {
    try {
      const data = {
        status: popupType === "accept" ? "accepted" : popupType === "reject" ? "rejected" : undefined,
        is_active: popupType === "disable" ? false : popupType === "enable" ? true : undefined,
        reason: popupType === "reject" ? reason : undefined
      };

      const endpoint = popupType === "accept" || popupType === "reject" 
        ? `/user/cms/update/${selectedMember.id}`
        : `/user/cms/update/${selectedMember.id}/access`;

      await axios.put(endpoint, data);
      handleClosePopup();
      // Redirect to members list page after action
      history.push("/membersList");
    } catch (error) {
      console.log(error);
    }
  };

  if (!member) {
    return <div>Loading...</div>;
  }

  console.log("member", member);

  return (
    <div className="member-details-outer-wrap">
      <Container>
        <BreadCrumb data={breadcrumb}></BreadCrumb>
        <div className="member-details-wrap">
          <Row>
            <Col sm={12}>
              <div className="member-header">
                <div className="member-header-main">
                  <MdKeyboardArrowLeft
                    onClick={() => history.push("/membersList")}
                    className="back-icon"
                  />
                  <h1>Member Details</h1>
                </div>
                {renderStatusButtons()}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="member-info">
                <div className="member-info-main">
                  <div className="member-info-head">
                    <div className="member-avatar">
                      <div className="avatar-initials">
                        {member.initials}
                      </div>
                    </div>

                    <div className="member-details">
                      <h2>{member.name}</h2>
                      <div className="member-email">
                        <MdOutlineMail />
                        <p>{member.email}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {member.status === "pending" ? (
                  <div className="acceptRjctBtn">
                    <Button variant="outline-primary" className="edit-button" onClick={handleAcceptClick}>
                      Accept
                    </Button>
                    <Button variant="outline-danger" className="disable-button" onClick={handleRejectClick}>
                      Reject
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="member-info-icon">
                      {member.status === "accepted" ? (
                        <img src={acceptedIcon} alt="" />
                      ) : (
                        member.status === "rejected" && (
                          <img src={rejectedIcon} alt="" />
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
              <hr className="divider" />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="member-additional-info">
                <div className="info-row">
                  <div>
                    <strong>Role:</strong>{" "}
                    <span>{member.roleAndSite.split('\n')[0]}</span>
                  </div>
                  <div>
                    <strong>Site:</strong>{" "}
                    <span>{member.roleAndSite.split('\n')[1]}</span>
                  </div>
                  <div>
                    <strong>Primary Specialty:</strong>{" "}
                    <span>
                      {member.specialtyAndLocation.split('\n')[0]}
                    </span>
                  </div>
                  <div>
                    <strong>Work Location:</strong>{" "}
                    <span>
                      {member.specialtyAndLocation.split('\n')[1]}
                    </span>
                  </div>
                </div>
                <div className="info-row-end">
                  <div>
                    <strong>Year Training Was Completed:</strong>{" "}
                    <span>{member.yearTrainingCompleted}</span>
                  </div>
                  <div>
                    <strong>Request Date:</strong>{" "}
                    <span>{member.requestDate}</span>
                  </div>
                </div>
                {member.status === "rejected" && (
                  <div className="rejection-reason">
                    <strong>Rejection Reason:</strong>
                    <p>{member.rejectionReason}</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {popupType && selectedMember && (
        <MemberPopup
          type={popupType}
          member={selectedMember}
          onClose={handleClosePopup}
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default MemberDetails;

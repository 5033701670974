import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Form } from 'react-bootstrap';

const Select = (props) => {

	const handleChange = (event) => {
		props.onChange(event.target.value);
	};

	return (
		<Form.Group controlId={props.id} className={`${props.containerStyle} form-group`}>
			{props.label && <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>}
			<Form.Select
				disabled={props.disabled}
				isValid={props.isValid}
				isInvalid={props.isInvalid}
				onChange={handleChange}
				size={props.size}
				value={props.defaultValue}
				id={props.id}
			>
				{
					props.data.map((item, index) => {
						return (
							<option value={item.value} disabled={item.disabled} key={index}>{item.label}</option>
						)
					})
				}
			</Form.Select>
			<Form.Control.Feedback type={`${props.isValid ? 'valid' : ''} ${props.isInvalid ? 'invalid' : ''}`}>{props.helperText}</Form.Control.Feedback>
		</Form.Group>
	)
}

/**
	* label : set label text
	* id :  set element id 
	* style : Manually style the checkbox/ radio
	* labelStyle : Manually style the label
	* containerStyle : Manually style the wrapper
	* helperText : providing helper text 
	* disabled : Disables the control.
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* data : Providing array of object 
	* onChange : handlers from firing regardless of the rendered element.
*/


Select.propTypes = {
	label: PropTypes.string,
	id: PropTypes.string,
	defaultValue: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
	labelStyle: PropTypes.string,
	containerStyle: PropTypes.string,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	isValid: PropTypes.bool,
	isInvalid: PropTypes.bool,
	data: PropTypes.array,
	onChange: PropTypes.func
};

Select.defaultProps = {
	label: '',
	id: '',
	defaultValue: '',
	size: '',
	style: '',
	labelStyle: '',
	containerStyle: '',
	helperText: '',
	disabled: false,
	isValid: false,
	isInvalid: false,
	required: false,
	data: [{
		label: "Select",
		value: "",
		disabled: false,
	}],
	onChange: () => { },
}

export default Select
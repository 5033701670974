import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// framework
import { Breadcrumb } from "react-bootstrap";

// styles
import "./bread-crumb.scss";

const BreadCrumb = (props) => {
  // navigation
  const history = useHistory();
  const navigation = (href) => {
    history.push(href);
  };

  return (
    <div className="breadcrumb-wrap">
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            if (
              props.setShowPopup &&
              props.setPopupAction &&
              props.setGoToHome &&
              props.title &&
              props.title !== "View"
            ) {
              props.setGoToHome(true);
              props.setPopupAction("back");
              props.setShowPopup(true);
            } else {
              navigation("/home");
            }
          }}
        >
          <span className="icon-Home"></span> Home
        </Breadcrumb.Item>
        {props.data.map((item, index) => {
          return (
            <Breadcrumb.Item
              onClick={() => {
                if (
                  props.setShowPopup &&
                  !item.active &&
                  props.setPopupAction &&
                  props.title &&
                  props.title !== "View"
                ) {
                  props.setPopupAction("back");
                  props.setShowPopup(true);
                } else {
                  navigation(item.href);
                }
                // navigation(item.href);
              }}
              active={item.active}
              key={index}
            >
              {item.label}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

/**
 * label: set item label
 * active: set breadcrum item as active for current page
 */

BreadCrumb.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
};

BreadCrumb.defaultProps = {
  label: "",
  active: false,
  setShowPopup: false,
  setPopupAction: "",
  setGoToHome: false,
  title: "",
};

export default BreadCrumb;

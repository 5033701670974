import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// framework
import { Container, Row, Col, Card, Form } from "react-bootstrap";

// plugin
import { Formik } from "formik";
import * as Yup from "yup";

// styles
import "./login.scss";

// components
import { Btn } from "../../../components";

// utils
import { setSession, isAuthenticated, setUserDetails } from "../../../utils/helpers/Authentication";
import loginApi from "../../../utils/helpers/API/USER_API";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const history = useHistory();

    // login
    const login = () => {
        window.location.reload();
        history.push({
            pathname: "/home",
        });
    };

    const handleSubmit = async (data) => {
        setErrorMessage(null);
        setUserDetails(data.username);
        data["platform"] = "WEB";

        // Login Api
        const authorize = async () => {
            try {
                const response = await loginApi(data); // USER_API.LOGIN

                if (response) {
                    let data = response.data;
                    if (data.status === "success") {
                        if (data.user_type === "admin") {
                            setSession(data.access_token);
                            login();
                        } else {
                            setErrorMessage("Access Denied");
                        }
                    } else {
                        setErrorMessage(data.message);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        authorize();
    };

    useEffect(() => {
        if (isAuthenticated()) {
            history.push({ pathname: "/home" });
        }
    }, []);

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email("Invalid email format")
            .required("Required"),
        password: Yup.string().required("Required"),
    });

    return (
        <div className="screen-wrap login-screen-wrap">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="login-wrap">
                            <div className="login-card-wrap">
                                <Card>
                                    <div className="login-card-body login-action-body show">
                                        <Card.Body>
                                            <Card.Title className="text-wrap">
                                                <p className="subtitle1">CMS Login</p>
                                            </Card.Title>
                                            <Formik
                                                initialValues={{
                                                    username: "",
                                                    password: "",
                                                }}
                                                validationSchema={validationSchema}
                                                onSubmit={(values) => {
                                                    handleSubmit(values);
                                                }}
                                            >
                                                {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
                                                    <Form className="login-form-wrap">
                                                        <Form.Group controlId="username" className="login-form-group">
                                                            <Form.Control
                                                                placeholder="Enter Email"
                                                                type="input"
                                                                isInvalid={touched.username && !!errors.username}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.username}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group controlId="password" className="login-form-group">
                                                            <Form.Control
                                                                placeholder="Password"
                                                                type={showPassword ? "input" : "password"}
                                                                isInvalid={touched.password && !!errors.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="login-form-group" controlId="show-password">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label="Show Password"
                                                                defaultChecked={showPassword}
                                                                onChange={() => setShowPassword(!showPassword)}
                                                            />
                                                        </Form.Group>
                                                        {errorMessage && (
                                                            <div className="d-grid mb-3">
                                                                <p className="text-danger">Invalid Email or Password</p>
                                                            </div>
                                                        )}
                                                        <div className="d-grid">
                                                            <Btn
                                                                variant="primary"
                                                                label="Login"
                                                                onClick={handleSubmit}
                                                                loader={true}
                                                            />
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;

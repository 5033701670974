import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import { BreadCrumb } from "../../../src/components";
import CustomDropdown from "./CustomDropdown";
import SearchBox from "./SearchBox";
import { PiSuitcaseSimple } from "react-icons/pi";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import OptionsCard from "./OptionsCard";
import MemberPopup from "./MemberPopup";
import axios from "../../utils/axios/api"; // Importing the axios instance
import "./MembersList.scss";
import toastr from '../../utils/Toastr';

const MembersList = () => {
  const [membersList, setMembersList] = useState([]);
  const [membersTab, setMembersTab] = useState(localStorage.getItem('membersTab') || "pending");
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cachedData, setCachedData] = useState({
    pending: [],
    accepted: [],
    rejected: []
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showOptionsCard, setShowOptionsCard] = useState(null);
  const [popupType, setPopupType] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);

  const breadcrumb = [
    {
      label: "Members",
      href: "#",
      active: true,
    },
  ];

  const history = useHistory();

  useEffect(() => {
    fetchMembers();
    fetchRoles();
    fetchSites();
    fetchPendingCount();
  }, [membersTab]);

  useEffect(() => {
    fetchMembers();
  }, [selectedRoles, selectedSites]);

  useEffect(() => {
    if (searchValue === "") {
      fetchMembers();
    }
  }, [searchValue]);

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const fetchMembers = async () => {
    setIsLoading(true);
    try {
      const status =
        membersTab === "pending"
          ? "pending"
          : membersTab === "accepted"
          ? "accepted"
          : "rejected";
      const body = {};
      if (selectedRoles.length > 0) body.role_id = selectedRoles;
      if (selectedSites.length > 0) body.site_id = selectedSites;

      const response = await axios.post(
        `/user/cms/list?status=${status}`,
        body
      );
      const members = response.data.users.map((member) => ({
        id: member._id,
        name: `${capitalize(member.first_name)} ${capitalize(
          member.last_name
        )}`,
        initials: `${member.first_name
          .charAt(0)
          .toUpperCase()}${member.last_name.charAt(0).toUpperCase()}`,
        email: member.username,
        roleAndSite: `${member.roles?.role_display_name || "N/A"}\n${
          member.sites?.site_display_name || "N/A"
        }`,
        siteId: `${member.sites?._id}`,
        specialtyAndLocation: `${
          member.specialty?.map((s) => s.specialty_display_name).join(", ") ||
          "N/A"
        }\n${
          member.work_location
            ?.map((w) => w.work_location_display_name)
            .join(", ") || "N/A"
        }`,
        specialityId: `${member.specialty?.map((s) => s._id)}`,
        locationId: `${member.work_location?.map((w) => w._id)}`,
        requestDate: new Date(member.created_at).toLocaleString(),
        yearTrainingCompleted: member.year_of_completed_training,
        rejectionReason: member.reason || "-",
        status: member.status,
        status_updated_at: member.status_updated_at
          ? new Date(member.status_updated_at).toLocaleString()
          : null,
        isActive: member.is_active,
      }));

      // Sorting logic
      if (membersTab === "accepted" || membersTab === "rejected") {
        members.sort((a, b) => {
          const dateA = new Date(a.status_updated_at);
          const dateB = new Date(b.status_updated_at);
          return dateB - dateA;
        });
      }

      setCachedData((prev) => ({
        ...prev,
        [membersTab]: members
      }));
      setMembersList(members);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPendingCount = async () => {
    try {
      const response = await axios.post(`/user/cms/list?status=pending`);
      setPendingCount(response.data.users.length);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`/user/cms/roles`);
      const roles = response.data.data.map((role) => ({
        id: role._id,
        name: role.role_display_name,
      }));
      setRoleOptions(roles);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const response = await axios.get(`/user/sites`);
      const sites = response.data.data.map((site) => ({
        id: site._id,
        name: site.site_display_name,
      }));
      setSiteOptions(sites);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDotsClick = (index) => {
    setShowOptionsCard(showOptionsCard === index ? null : index);
  };

  const handleEdit = (member) => {
    history.push({
      pathname: `/edit-member/${member.id}`,
      state: { member },
    });
    setShowOptionsCard(null);
  };
  

  const handleDisable = (member) => {
    setPopupType("disable");
    setSelectedMember(member);
    setShowOptionsCard(null);
  };

  const handleEnable = (member) => {
    setPopupType("enable");
    setSelectedMember(member);
    setShowOptionsCard(null);
  };

  const handleOutsideClick = (event) => {
    if (showOptionsCard !== null && !event.target.closest(".options-card")) {
      setShowOptionsCard(null);
    }
  };

  const handleAcceptClick = (member) => {
    setPopupType("accept");
    setSelectedMember(member);
  };

  const handleRejectClick = (member) => {
    setPopupType("reject");
    setSelectedMember(member);
  };

  const handleApproveClick = (member) => {
    setPopupType("approve");
    setSelectedMember(member);
  };

  const handleClosePopup = () => {
    setPopupType("");
    setSelectedMember(null);
  };

  const handleSubmitPopup = async (reason) => {
    try {
      const data = {
        status:
          popupType === "accept"
            ? "accepted"
            : popupType === "reject"
            ? "rejected"
            : popupType === "approve"
            ? "accepted"
            : undefined,
        is_active:
          popupType === "disable"
            ? false
            : popupType === "enable"
            ? true
            : undefined,
        reason: popupType === "reject" ? reason : null,
      };

      const endpoint =
        popupType === "accept" || popupType === "reject" || popupType === "approve"
          ? `/user/cms/update/${selectedMember.id}`
          : `/user/cms/update/${selectedMember.id}/access`;

      await axios.put(endpoint, data);
      handleClosePopup();
      
      // Update cache data accordingly
      setCachedData((prev) => {
        const updatedCache = { ...prev };
        if (popupType === "accept" || popupType === "reject" || popupType === "approve") {
          updatedCache.pending = updatedCache.pending.filter(m => m.id !== selectedMember.id);
        } else if (popupType === "disable" || popupType === "enable") {
          updatedCache.accepted = updatedCache.accepted.map(m => m.id === selectedMember.id ? { ...m, isActive: !m.isActive } : m);
        }
        return updatedCache;
      });

      if (popupType === "accept" || popupType === "reject" || popupType === "approve") {
        setCachedData((prev) => ({
          ...prev,
          accepted: [],
          rejected: [],
        }));
      }

      fetchMembers(); // Refresh the members list after update
      fetchPendingCount(); // Refresh the pending count after update
      toastr.success(`${popupType.charAt(0).toUpperCase() + popupType.slice(1)} request was successful.`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showOptionsCard !== null) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showOptionsCard]);

  const navigateToMemberDetails = (member) => {
    history.push({
      pathname: `/member-details/${member.id}`,
      state: { member },
    });
  };
  
  useEffect(() => {
    const getDropdownData = async () => {
      try {
        const siteData = await axios.get("user/sites");
        localStorage.setItem("siteData", JSON.stringify(siteData.data.data));

        const roleData = await axios.get("user/cms/roles");
        localStorage.setItem("roleData", JSON.stringify(roleData.data.data));

        const specialityData = await axios.get("user/specialty");
        localStorage.setItem(
          "specialityData",
          JSON.stringify(specialityData.data.data)
        );

        const workLocationData = await axios.get("user/work-location");
        localStorage.setItem(
          "workLocationData",
          JSON.stringify(workLocationData.data.data)
        );
      } catch (error) {
        console.log(error);
      }
    }

    getDropdownData()
  }, [])

  const handleSearchChange = async (value) => {
    setSearchValue(value);
    if (value) {
      try {
        const status =
          membersTab === "pending"
            ? "pending"
            : membersTab === "accepted"
            ? "accepted"
            : "rejected";
        const response = await axios.get(
          `/user/cms/search/${value.toLowerCase()}?status=${status}`
        );
        const members = response.data.data
          .filter((member) => member.status === status) // Filter by status
          .map((member) => ({
            id: member._id,
            name: `${capitalize(member.first_name)} ${capitalize(
              member.last_name
            )}`,
            initials: `${member.first_name
              .charAt(0)
              .toUpperCase()}${member.last_name.charAt(0).toUpperCase()}`,
            email: member.username,
            roleAndSite: `${member.roles?.role_display_name || "N/A"}\n${
              member.sites?.site_display_name || "N/A"
            }`,
            specialtyAndLocation: `${
              member.specialty
                ?.map((s) => s.specialty_display_name)
                .join(", ") || "N/A"
            }\n${
              member.work_location
                ?.map((w) => w.work_location_display_name)
                .join(", ") || "N/A"
            }`,
            requestDate: new Date(member.created_at).toLocaleString(),
            yearTrainingCompleted: member.year_of_completed_training,
            rejectionReason: member.reason || "-",
            status: member.status,
            status_updated_at: member.status_updated_at
              ? new Date(member.status_updated_at).toLocaleString()
              : null,
            isActive: member.is_active,
          }));
        setMembersList(members);
      } catch (error) {
        console.log(error);
      }
    } else {
      fetchMembers();
    }
  };

  function spinnerLoader() {
    return (
      <div className="mt-5 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const handleTabSelect = (eventKey) => {
    if (!isLoading) {
      setMembersTab(eventKey);
      localStorage.setItem('membersTab', eventKey);
    }
  };

  // New handler for role filter changes
  const handleRoleFilterChange = (updatedSelectedRoles) => {
    setSelectedRoles(updatedSelectedRoles);
  };

  // New handler for site filter changes
  const handleSiteFilterChange = (updatedSelectedSites) => {
    setSelectedSites(updatedSelectedSites);
  };

  return ! loader? (
    <React.Fragment>
      <div className="members-screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="members-screen-view-wrap">
            <div className="members-screen-view-row no-border">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="members-screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Members</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12} className="d-flex align-items-center gap-3">
                  <CustomDropdown
                    title="By Role"
                    options={roleOptions}
                    selectedOptions={selectedRoles}
                    setSelectedOptions={setSelectedRoles}
                    Icon={PiSuitcaseSimple}
                    onFilterChange={handleRoleFilterChange} // Pass the new handler
                  />
                  <CustomDropdown
                    title="By Site"
                    options={siteOptions}
                    selectedOptions={selectedSites}
                    setSelectedOptions={setSelectedSites}
                    Icon={MdOutlineLocationOn}
                    onFilterChange={handleSiteFilterChange} // Pass the new handler
                  />
                  <SearchBox
                    value={searchValue}
                    onChange={handleSearchChange}
                    onClear={() => {
                      fetchMembers()
                      setSearchValue('')
                    }}
                    setSearchValue={setSearchValue}
                  />
                </Col>
              </Row>
            </div>
            <div className="members-screen-view-row no-border">
              <div className="members-tabs-wrap">
                <Nav
                  variant="tabs"
                  defaultActiveKey={membersTab}
                  onSelect={handleTabSelect}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="pending" disabled={isLoading}>
                      New Requests ({pendingCount})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="accepted" disabled={isLoading}>Accepted</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rejected" disabled={isLoading}>Rejected</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>

            {!isLoading ? (
              <div className="members-screen-view-row no-border">
                <Row>
                  <Col sm={12}>
                    <div className="members-table-wrap">
                      <table className="members-table-striped">
                        <thead>
                          <tr>
                            <th>Member Name</th>
                            <th>Email</th>
                            <th>Role and Site</th>
                            <th>
                              Primary Specialty <br /> and Work Location
                            </th>
                            <th>Request Date</th>
                            {membersTab === "accepted" && (
                              <th>Accepted Date</th>
                            )}
                            {membersTab === "rejected" && <th>Reject Date</th>}
                            {membersTab === "rejected" && (
                              <th>Rejection Reason</th>
                            )}
                            {membersTab === "rejected" && <th></th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {membersList.length > 0 ? (
                            membersList.map((member, index) => (
                              <tr
                                key={index}
                                className={
                                  !member.isActive ? "disabled-row" : ""
                                }
                              >
                                <td>
                                  <div className="member-name-wrapper">
                                    <div
                                      className={
                                        !member.isActive
                                          ? "initials initialRed"
                                          : "initials intitialNormal"
                                      }
                                    >
                                      {member.initials}
                                    </div>
                                    <span
                                      onClick={() =>
                                        navigateToMemberDetails(member)
                                      }
                                      style={{ whiteSpace: searchValue ? "normal" : "nowrap" }}
                                    >
                                      {searchValue ? member.name : truncate(member.name, 13)}
                                    </span>
                                  </div>
                                </td>
                                <td>{truncate(member.email, 20)}</td>
                                <td className="role-site">
                                  {member.roleAndSite.split("\n")[0]}
                                  <div className="site">
                                    {member.roleAndSite.split("\n")[1]}
                                  </div>
                                </td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigateToMemberDetails(member)
                                  }
                                  className="specialty-location"
                                >
                                  {
                                    member.specialtyAndLocation
                                      .split("\n")[0]
                                      .split(", ")[0]
                                  }
                                  {member.specialtyAndLocation
                                    .split("\n")[0]
                                    .split(", ").length > 1 && (
                                    <span className="more">
                                      {" "}
                                      {"..."} +
                                      {member.specialtyAndLocation
                                        .split("\n")[0]
                                        .split(", ").length - 1}
                                    </span>
                                  )}
                                  <div className="location">
                                    {
                                      member.specialtyAndLocation
                                        .split("\n")[1]
                                        .split(", ")[0]
                                    }
                                    {member.specialtyAndLocation
                                      .split("\n")[1]
                                      .split(", ").length > 1 && (
                                      <span className="more">
                                        {" "}
                                        <span>...</span> +
                                        {member.specialtyAndLocation
                                          .split("\n")[1]
                                          .split(", ").length - 1}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {member.requestDate.split(", ")[0]}
                                  <div className="time">
                                    {member.requestDate.split(", ")[1]}
                                  </div>
                                </td>
                                {membersTab === "accepted" && (
                                  <td>
                                    {member.status_updated_at?.split(", ")[0]}
                                    <div className="time">
                                      {member.status_updated_at?.split(", ")[1]}
                                    </div>
                                  </td>
                                )}
                                {membersTab === "rejected" && (
                                  <td>
                                    {member.status_updated_at?.split(", ")[0]}
                                    <div className="time">
                                      {member.status_updated_at?.split(", ")[1]}
                                    </div>
                                  </td>
                                )}
                                {membersTab === "rejected" && (
                                  <td className="rejection-reason">
                                    {truncate(member.rejectionReason, 16)}
                                  </td>
                                )}
                                {membersTab === "rejected" && (
                                  <td>
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      className="members-approve-btn"
                                      onClick={() =>
                                        handleApproveClick(member)
                                      }
                                    >
                                      Approve
                                    </Button>
                                  </td>
                                )}
                                <td>
                                  {membersTab === "pending" && (
                                    <div className="members-button-group">
                                      <Button
                                        variant="outline-primary"
                                        size="sm"
                                        className="members-custom-btn"
                                        onClick={() =>
                                          handleAcceptClick(member)
                                        }
                                      >
                                        Accept
                                      </Button>{" "}
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="members-custom-btn"
                                        onClick={() =>
                                          handleRejectClick(member)
                                        }
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  )}
                                  {membersTab === "accepted" && (
                                    <div
                                      className="members-three-dots"
                                      onClick={() => handleDotsClick(index)}
                                    >
                                      <BsThreeDotsVertical />
                                      {showOptionsCard === index && (
                                        <OptionsCard
                                          onEdit={() => handleEdit(member)}
                                          onDisable={() =>
                                            handleDisable(member)
                                          }
                                          onEnable={() => handleEnable(member)}
                                          isActive={member.isActive}
                                        />
                                      )}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={
                                  membersTab === "accepted"
                                    ? 7
                                    : membersTab === "rejected"
                                    ? 8
                                    : 6
                                }
                              >
                                No members found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              spinnerLoader()
            )}
          </div>
        </Container>
        {popupType && selectedMember && (
          <MemberPopup
            type={popupType}
            member={selectedMember}
            onClose={handleClosePopup}
            onSubmit={handleSubmitPopup}
          />
        )}
      </div>
    </React.Fragment>
  ) : (
    spinnerLoader()
  );
};

export default MembersList;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// framework
import { Form, Col } from "react-bootstrap";

const Check = (props) => {
  // handle on change of check
  const handleChange = (value) => (event) => {
    // let tempArr = dataArr.map(data => data.value == value);

    let _dataArr = [...props.data];
    _dataArr.some((elems) => {
      if (props.type == "radio") {
        elems.isSelected = false;
      }
      if (elems.value === value) {
        elems.isSelected = !elems.isSelected;
      }
    });

    // console.log(_dataArr, "tempArr")
    props.onChange(value, event, _dataArr);
  };

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} form-group`}
    >
      {props.label && (
        <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>
      )}
      <div className="form-check-wrap">
        {props.data.map((item, index) => {
          return (
            <Form.Check
              value={item.value}
              key={index}
              label={item.label}
              id={`${props.id}` + "_" + `${index}`}
              required={props.required}
              name={`${props.id}`}
              type={props.type}
              disabled={item.disabled}
              isValid={props.isValid}
              //isInvalid={props.isInvalid} 
              readOnly={props.readOnly}
              onChange={handleChange(item.value)}
              className={`${props.style}`}
              inline={props.inline}
              defaultChecked={
                props.type === "radio"
                  ? item.value === props.defaultValue
                  : item.isSelected
              }
              // defaultChecked={item.isSelected}
            />
          );
        })}
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * label : set label text
 * id :  set element id
 * type : The type of checkable. 'radio' | 'checkbox' | 'switch'
 * style : Manually style the checkbox/ radio
 * labelStyle : Manually style the label
 * containerStyle : Manually style the wrapper
 * helperText : providing helper text
 * disabled : Disables the control.
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * required : set is required or not
 * defaultChecked : set default value
 * inline : Groups controls horizontally with other FormChecks.
 * data : Providing array of object
 * onChange : handlers from firing regardless of the rendered element.
 */

Check.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  inline: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

Check.defaultProps = {
  label: "",
  id: "",
  type: "checkbox",
  style: "",
  labelStyle: "",
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  defaultChecked: false,
  data: [
    {
      label: "Check",
      value: "default",
      disabled: false,
    },
  ],
  onChange: () => {},
};

export default Check;

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Form, ListGroup } from "react-bootstrap";
import { BreadCrumb, InputDate } from "../../../components";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { CiCirclePlus, CiEdit } from "react-icons/ci";
import { FaGripVertical } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { BsDownload, BsTextareaResize } from "react-icons/bs";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckboxOutline,
} from "react-icons/io";
import { MdRadioButtonChecked } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { RxSlider } from "react-icons/rx";
// /assests/icons
import { GLOBAL_ICONS } from "../../../assets/icons/global-icons";
import ReactQuill from "react-quill";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "react-quill/dist/quill.snow.css";
import "./CreateSurvey.scss";
import CustomSwitch from "../CustomSwitch/CustomSwitch";

//utils
import axios from "../../../utils/axios/api";

//component
import SurveyDetails from "../SurveyDetails/surveyDetails";

//validations
import { surveyQuestionsValidation } from "../../../validations/surveyQuestions";

import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import toastr from "../../../utils/Toastr";
import { mapQuestionToPayload } from "../../../utils/helpers/helperFunctions";
import { setEndTimeTo2355, setStartTime } from "../../../utils/dates";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../redux/actions/toastActions";
import { TOAST_MESSAGES } from "../../../constants/toastMessages";
import {
  setActiveTab,
  setNeedsRefresh,
  setSurveyIds,
} from "../../../redux/actions/surveyActions";
import useOutsideClick from "../../../utils/CustomHooks/useOutsideClick";

const CreateSurvey = ({
  title,
  surveyName,
  setSurveyName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  questions,
  setQuestions,
  questionCount,
  setQuestionCount,
  details,
  onSaveAndProceed,
  user,
  updateSurveyNameById,
  updateQuestionsById,
  answerCount,
  setAnswerCount,
  handleInactive,
  handleEdit,
  editSurveyId,
  isActiveSurvey,
  handleActivate,
  breadcrumb,
  setSurveyCreateId,
  surveyMember,
  surveyStatus,
  handleClone,
  handleFileDownload,
  handleDeleteSurvey,
  surveyCreateId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxSurveyId = useSelector((state) => state.serveys.surveyId);
  let pathname = location.pathname.split("/");
  let id = pathname[2];

  //using the following variables to show the error messages of the options under questions
  let radioOrMultichoiceQuestionOptions = "";
  let sliderQuestionOptions = {
    questionTitle: "",
    minValue: "",
    maxValue: "",
    leftLabel: "",
    rightLabel: "",
  };
  let tabQuestionOptions = "";

  //storing the data of the surveyList in the following variable received while redirecting
  let listData = location.state;

  const [validationForm, setValidationForm] = useState(false);
  const [questionLengthValidation, setQuestionLengthValidation] =
    useState(false);

  const [expandedQuestionId, setExpandedQuestionId] = useState(null);
  const [surveyId, setSurveyId] = useState(null);

  //using the following state to display the error messages of question expansion
  const [errorMessagesOfQuestion, setErrorMessagesOfQuestion] = useState([]);

  //using the following state to get the questionId and orderId when user click on drag the div
  const [questionOrderOnDrag, setQuestionOrderOnDrag] = useState(null);
  const [dateError, setDateError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupAction, setPopupAction] = useState(null);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [goToHome, setGoToHome] = useState(false);
  const [originalQuestionText, setOriginalQuestionText] = useState({});
  const [questionsFromApi, setQuestionsFromApi] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownError, setDropdownError] = useState("");
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setShowDropdown(false));

  const mainText = surveyId
    ? "You have unsaved changes in your survey. Please save your work as a draft before leaving."
    : "If you go back now, any information you've entered will be lost. Are you sure you want to leave without saving?";

  const btnTitle = surveyId ? "Save as Draft" : "Yes, Go Back";

  const handleAddQuestion = async () => {
    if (!surveyName || !startDate || !endDate) {
      setValidationForm(true);
      return;
    } else {
      setValidationForm(false);
      setQuestionLengthValidation(false);
    }

    if (new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date must be before the end date.");
      return;
    } else {
      setDateError("");
    }

    try {
      if (title === "Add" && !surveyId) {
        const surveyResponse = await axios.post("survey/create", {
          username: user,
          survey_name: surveyName,
          status: "draft", //updated by yasar by default survey will be in draft stage
          is_active: true,
          is_pulsed_active: false,
          start_date: setStartTime(startDate),
          end_date: setEndTimeTo2355(endDate),
        });

        // Check if survey already exists
        if (surveyResponse?.data?.message === "Survey already exists.") {
          setDateError("Survey name already exists.");
          return; // Exit early if survey already exists
        }

        const currentSurveyId = surveyResponse?.data?.id;
        setSurveyId(currentSurveyId);
        setSurveyCreateId(currentSurveyId);
      }

      if (updateSurveyNameById !== undefined && title === "Edit") {
        setSurveyId(id);
        updateSurveyNameById("draft");
      }
      setQuestions([
        ...questions,
        {
          id: questionCount + 1,
          questionText: "",
          question_type: "radio",
          order: questionCount + 1,
          options: [
            {
              _id: 0,
              option_text: "",
              option_value: "",
              image: "",
              order: 0,
              is_active: true,
            },
            {
              _id: 1,
              option_text: "",
              option_value: "",
              image: "",
              order: 1,
              is_active: true,
            },
          ],
          isMandatory: false,
          isActive: true,
          placeholderText: "",
          note: "",
          numericOnly: false,
          isSaved: false,
          _id: null,
          subQuestions: [
            {
              sliderQuestion: "",
              minValue: 0,
              maxValue: 100,
              leftLabel: "",
              rightLabel: "",
            },
          ],
        },
      ]);

      setQuestionCount(questionCount + 1);
      setExpandedQuestionId(questionCount + 1);
    } catch (error) {
      console.error("Failed to create survey:", error);
    }
  };

  const handleSaveAndProceed = async () => {
    if (!surveyName || !startDate || !endDate) {
      setValidationForm(true);
      return;
    }
    setValidationForm(false);
    setQuestionLengthValidation(false);

    if (new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date must be before the end date.");
      return;
    }
    setDateError("");

    let currentSurveyId = surveyId;
    let newSurveyId = null;

    // Create a new survey if it's a clone and surveyId is not present
    if (title === "Clone" && !currentSurveyId) {
      const surveyResponse = await axios.post("survey/create", {
        username: user,
        survey_name: surveyName,
        status: "draft",
        is_active: true,
        is_pulsed_active: false,
        start_date: setStartTime(startDate),
        end_date: setEndTimeTo2355(endDate),
      });

      newSurveyId = surveyResponse?.data.id;
      setSurveyId(newSurveyId);

      // Update Redux store with the new survey ID and await completion
      await dispatch(setSurveyIds(newSurveyId));
    }

    const finalSurveyId =
      newSurveyId ||
      (title === "Edit" ? currentSurveyId : reduxSurveyId) ||
      currentSurveyId;

    // Filter out new questions (questions without an _id) for validation
    const newQuestions = questions.filter((question) => !question._id);

    // Validate new questions
    const invalidQuestions = newQuestions.filter((question) => {
      return (
        !question.questionText || // Check if question text is empty
        (question.question_type === "slider_group" &&
          question.options.some((option) => {
            return (
              option.min_value === "" ||
              option.max_value === "" ||
              option.min_value >= option.max_value ||
              option.min_text === option.max_text ||
              !option.question_title
            );
          }))
      );
    });

    // If any new questions are invalid, show error and stop
    if (invalidQuestions.length > 0) {
      setErrorMessagesOfQuestion(
        invalidQuestions.map((q) => q.id || "new_" + questions.indexOf(q))
      );
      return;
    }

    const questionsPayload =
      title === "Edit"
        ? newQuestions.map((question, index) =>
            mapQuestionToPayload(question, index, finalSurveyId, user)
          )
        : questions.map((question, index) =>
            mapQuestionToPayload(question, index, finalSurveyId, user)
          );

    let allQuestionIds = surveyQuestionsValidation(
      questionsPayload,
      expandedQuestionId
    );
    setErrorMessagesOfQuestion(allQuestionIds);

    if (allQuestionIds.length === 0) {
      try {
        await axios.post("survey/question/createAll", {
          questions: questionsPayload,
        });
        onSaveAndProceed();
      } catch (error) {
        console.error("Failed to save questions:", error);
      }
    }

    if (questionOrderOnDrag !== null) {
      handleQuestionOrder();
    }
  };

  //using the following function to call the Api when there is change of the order of the questions
  function handleQuestionOrder() {
    let url = `/survey/question/update/order?surveyId=${
      surveyId !== null ? surveyId : editSurveyId
    }`;
    let response = axios.put(url, { questions: questionOrderOnDrag });
    console.log("response", response);
  }

  const handleDeleteQuestion = async (id) => {
    const question = questions.find((q) => q.id === id);

    if (question._id) {
      try {
        const res = await axios.delete(
          `survey/question/delete/${question._id}`
        );
        if (res?.data?.status === "success") {
          dispatch(
            showToast(
              TOAST_MESSAGES.QUESTION_DELETE_SUCCESS.message,
              TOAST_MESSAGES.QUESTION_DELETE_SUCCESS.severity
            )
          );
        }
      } catch (error) {
        dispatch(
          showToast(
            TOAST_MESSAGES.QUESTION_DELETE_FAILURE.message,
            TOAST_MESSAGES.QUESTION_DELETE_FAILURE.severity
          )
        );
        return;
      }
    }

    setQuestions(questions.filter((q) => q.id !== id));
  };

  const handleQuestionChange = (id, field, value) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id ? { ...question, [field]: value } : question
    );
    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    axios
      .get("/survey/question/all")
      .then((response) => {
        const validQuestions = response.data.list.filter(
          (item) => typeof item === "string" && item.trim().length > 0
        );
        setQuestionsFromApi(validQuestions);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  }, []);
  // Handle text input change and filter questions
  const handleInputChange = (question, e) => {
    const inputText = e.target.value;

    if (inputText.length <= 200) {
      handleQuestionChange(question.id, "questionText", inputText);
      setDropdownError(""); // Clear the error message if manually typed

      // Filter questions based on input
      const filtered = questionsFromApi
        .filter((q) => typeof q === "string" && q.trim().length > 0) // Filter out non-strings and empty strings
        .filter((q) => q.toLowerCase().includes(inputText.toLowerCase()));

      setFilteredQuestions(filtered);
      setShowDropdown(filtered.length > 0);
    }
  };

  const handleSelectQuestion = (questionTitle, question) => {
    if (questionTitle.length > 200) {
      // Show error if question exceeds 200 characters and keep dropdown open
      setDropdownError("Selected question exceeds 200 characters.");
      setShowDropdown(true); // Ensure dropdown stays open
    } else {
      // Clear error and set the question if it's valid
      setDropdownError(""); // Clear any previous error
      handleQuestionChange(question.id, "questionText", questionTitle);
      setShowDropdown(false); // Close the dropdown if the question is valid
    }
  };

  const handleOptionChange = (qId, field, oIndex, value) => {
    const updatedQuestions = questions.map((question) =>
      question.id === qId
        ? {
            ...question,
            options: question.options.map((opt, index) =>
              index === oIndex ? { ...opt, [field]: value } : opt
            ),
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const handleRemoveOption = (qId, oIndex) => {
    const updatedQuestions = questions.map((question) =>
      question.id === qId
        ? {
            ...question,
            options: question.options.map((option, index) =>
              index === oIndex ? { ...option, is_active: false } : option
            ),
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (qId) => {
    const updatedQuestions = questions.map((question) => {
      if (question.id === qId) {
        const incompleteOption = question.options.find((option) => {
          return option.is_active && !option.option_text;
        });

        if (incompleteOption) {
          setErrorMessagesOfQuestion((prevErrors) => [...prevErrors, qId]);
          return question;
        }

        setErrorMessagesOfQuestion((prevErrors) =>
          prevErrors.filter((errorId) => errorId !== qId)
        );
        return {
          ...question,
          options: [
            ...question.options.filter((option) => option.is_active !== false),
            { option_text: "", is_active: true },
          ],
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
  };

  const toggleQuestion = (id, isCancel = false) => {
    if (isCancel) {
      // Revert to original question text on cancel
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === id ? { ...q, questionText: originalQuestionText[id] } : q
        )
      );
    } else {
      const question = questions.find((q) => q.id === id);
      setOriginalQuestionText((prev) => ({
        ...prev,
        [id]: question.questionText,
      }));
    }
    setExpandedQuestionId(expandedQuestionId === id ? null : id);
  };

  const handleQuestionTypeChange = (id, type) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id
        ? {
            ...question,
            question_type: type,
            options:
              type === "tab"
                ? [
                    {
                      _id: 0,
                      option_text: "happy",
                      option_value: "happy",
                      image: "GLOBAL_ILLUSTRATION.happy",
                      order: 0,
                      is_active: true,
                    },
                    {
                      _id: 1,
                      option_text: "sad",
                      option_value: "sad",
                      image: "GLOBAL_ILLUSTRATION.sad",
                      order: 1,
                      is_active: true,
                    },
                  ]
                : type === "radio" || type === "checkbox"
                ? [
                    {
                      option_text: "",
                      option_value: "",
                    },
                    {
                      option_text: "",
                      option_value: "",
                    },
                  ]
                : [""],

            placeholderText: "",
            note: "",
            numericOnly: false,
            subQuestions:
              type === "slider_group"
                ? [
                    {
                      sliderQuestion: "",
                      minValue: 0,
                      maxValue: 100,
                      leftLabel: "",
                      rightLabel: "",
                    },
                  ]
                : [],
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const handleSubQuestionChange = (id, subQuestionIndex, field, value) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id
        ? {
            ...question,
            options: question.options.map((subQuestion, index) =>
              index === subQuestionIndex
                ? { ...subQuestion, [field]: value }
                : subQuestion
            ),
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const handleAddSubQuestion = (id) => {
    const updatedQuestions = questions.map((question) => {
      if (question.id === id) {
        const incompleteSubQuestion = question.options.find((option) => {
          return (
            option.is_active &&
            (!option.question_title ||
              !option.min_value ||
              !option.max_value ||
              !option.min_text ||
              !option.max_text)
          );
        });

        // If an incomplete sub-question exists, add this question ID to the error list
        if (incompleteSubQuestion) {
          setErrorMessagesOfQuestion((prevErrors) =>
            prevErrors.includes(id) ? prevErrors : [...prevErrors, id]
          );
          return question; // Return without adding a new sub-question since there's an error
        }
        setErrorMessagesOfQuestion((prevErrors) =>
          prevErrors.filter((errorId) => errorId !== id)
        );
        return {
          ...question,
          options: [
            ...question.options.filter((option) => option.is_active !== false),
            {
              ans_code: "ans_code_7",
              question_title: "",
              is_mandatory: false,
              min_value: "",
              max_value: "",
              min_text: "",
              max_text: "",
              is_active: true,
            },
          ],
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
  };

  const handleRemoveSubQuestion = (id, subQuestionIndex) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id
        ? {
            ...question,
            options: question.options.map((option, index) =>
              index === subQuestionIndex
                ? { ...option, is_active: false }
                : option
            ),
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, removed);
    let questionsOrder = reorderedQuestions?.map((item, index) => {
      return { questionId: item._id, order: index + 1 };
    });

    setQuestionOrderOnDrag(questionsOrder);
    setQuestions(reorderedQuestions);
  };

  const handleBackClick = () => {
    setPopupAction("back"); // Set the action as "back"
    setShowPopup(true); // Show the popup
  };

  // Handle delete icon click
  const handleDeleteClick = (questionId) => {
    setQuestionToDelete(questionId); // Store the question ID
    setPopupAction("delete"); // Set the action as "delete"
    setShowPopup(true); // Show the popup
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setQuestionToDelete(null);
    setPopupAction(null);
  };

  const handlePopupSubmit = () => {
    setShowPopup(false);

    if (popupAction === "delete") {
      handleDeleteQuestion(questionToDelete);
    } else if (popupAction === "back" && surveyId) {
      handleSaveAsDraft();
    } else if (goToHome && popupAction === "back") {
      history.push("/home");
    } else {
      history.push("/surveyManagement");
    }

    setQuestionToDelete(null);
    setPopupAction(null);
  };

  const handlePushToSurveyList = () => {
    history.push("/surveyManagement");
  };

  const handleSaveAsDraft = async () => {
    // Check for required fields for survey
    if (!surveyName || !startDate || !endDate) {
      setValidationForm(true);
      return;
    }
    setValidationForm(false);
    setQuestionLengthValidation(false);

    // Check for valid date range
    if (new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date must be before the end date.");
      return;
    }
    setDateError("");

    let currentSurveyId = surveyId;
    let newSurveyId = null;

    if (title === "Clone" && !currentSurveyId) {
      try {
        const surveyResponse = await axios.post("survey/create", {
          username: user,
          survey_name: surveyName,
          status: "draft",
          is_active: true,
          is_pulsed_active: false,
          start_date: setStartTime(startDate),
          end_date: setEndTimeTo2355(endDate),
        });

        newSurveyId = surveyResponse?.data.id;
        if (!newSurveyId) {
          console.error("Failed to create new survey. Survey ID is missing.");
          return;
        }

        setSurveyId(newSurveyId);
        await dispatch(setSurveyIds(newSurveyId));
      } catch (error) {
        console.error("Error creating survey:", error);
        return;
      }
    }
    // console.log("newSurveyId");
    let finalSurveyId;
    if (title === "Edit") {
      finalSurveyId = newSurveyId || currentSurveyId || id;
    } else {
      finalSurveyId = surveyCreateId || newSurveyId || reduxSurveyId || id;
    }

    // Filter out new questions (questions without an _id) for validation
    const newQuestions = questions.filter((question) => !question._id);

    // Validate new questions
    const invalidQuestions = newQuestions.filter((question) => {
      return (
        !question.questionText || // Check if question text is empty
        (question.question_type === "slider_group" &&
          question.options.some((option) => {
            return (
              option.min_value === "" ||
              option.max_value === "" ||
              option.min_value >= option.max_value ||
              option.min_text === option.max_text ||
              !option.question_title
            );
          }))
      );
    });

    // If any new questions are invalid, show error and stop
    if (invalidQuestions.length > 0) {
      setErrorMessagesOfQuestion(
        invalidQuestions.map((q) => q.id || "new_" + questions.indexOf(q))
      );
      return;
    }

    // Prepare payload for all questions (cloned + new)
    const questionsPayload =
      title === "Edit"
        ? newQuestions.map((question, index) =>
            mapQuestionToPayload(question, index, finalSurveyId, user)
          )
        : questions.map((question, index) =>
            mapQuestionToPayload(question, index, finalSurveyId, user)
          );

    // Run validation on questions and stop if there are issues
    let allQuestionIds = surveyQuestionsValidation(
      questionsPayload,
      expandedQuestionId
    );
    setErrorMessagesOfQuestion(allQuestionIds);
    if (allQuestionIds.length > 0) {
      return;
    }

    try {
      // Send all questions to the backend using createAll API
      if (questionsPayload.length > 0) {
        await axios.post("survey/question/createAll", {
          questions: questionsPayload,
        });
      }
    } catch (error) {
      console.error("Failed to save questions:", error);
    }

    if (questionOrderOnDrag !== null) {
      handleQuestionOrder();
    }

    const users_ids =
      surveyMember &&
      surveyMember?.map((item) => {
        return item._id;
      });

    try {
      let finalObj = {
        survey_id: id || surveyId,
        users_ids,
      };

      if (title === "Edit") {
        await axios.put("survey/user/update/map", finalObj);
        toastr.success("Survey saved as draft");
        dispatch(setActiveTab("draft"));
        dispatch(setNeedsRefresh(true));
        setTimeout(() => {
          history.push("/surveyManagement");
        }, 3000);
        updateSurveyNameById("draft");
      } else {
        await axios.post("survey/user/map", finalObj);
        toastr.success("Survey saved as draft");
        dispatch(setActiveTab("draft"));
        dispatch(setNeedsRefresh(true));
        setTimeout(() => {
          history.push("/surveyManagement");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="create-survey-outer-wrap">
      <Container>
        <BreadCrumb
          data={breadcrumb}
          setShowPopup={setShowPopup}
          setPopupAction={setPopupAction}
          setGoToHome={setGoToHome}
          title={title}
        ></BreadCrumb>
        <div className="create-survey-wrap">
          <Row>
            <Col sm={12}>
              <div className="survey-header">
                <div className="survey-header-main">
                  <MdKeyboardArrowLeft
                    onClick={
                      title === "View"
                        ? handlePushToSurveyList
                        : handleBackClick
                    }
                    className="back-icon"
                  />
                  <h1>
                    {title === "View"
                      ? surveyName
                      : `${title} Survey (Step 1/2)`}
                  </h1>
                </div>
                {title === "View" && (
                  <div className="question-count">
                    {/* <span>No. of Questions: {questions.length}</span> */}
                    {/* {!isActiveSurvey && ( */}
                    <>
                      {surveyStatus && surveyStatus === "draft" && (
                        <>
                          <div
                            className="question-btn-edit"
                            onClick={handleEdit}
                          >
                            <img
                              src={GLOBAL_ICONS.Edit}
                              alt=""
                              className="Icon"
                            />
                            <span>Edit</span>
                          </div>
                          <div
                            className="question-btn-delete"
                            onClick={handleDeleteSurvey}
                          >
                            <FiTrash />
                            <span>Delete</span>
                          </div>
                        </>
                      )}
                      {isActiveSurvey && (
                        <div
                          className={
                            isActiveSurvey
                              ? "qtn-btn-inactive"
                              : "qtn-btn-active"
                          }
                          onClick={
                            isActiveSurvey ? handleInactive : handleActivate
                          }
                        >
                          <img
                            src={
                              isActiveSurvey
                                ? GLOBAL_ICONS.Invoice
                                : GLOBAL_ICONS.GreenInvoice
                            }
                            alt=""
                          />
                          <span>
                            {isActiveSurvey ? "Inactivate" : "Activate"}
                          </span>
                        </div>
                      )}
                      {surveyStatus && surveyStatus !== "draft" && (
                        <>
                          <div
                            className="question-btn-clone"
                            onClick={handleClone}
                          >
                            <CiEdit />
                            <span>Clone</span>
                          </div>
                          <div
                            className="question-btn-report"
                            onClick={handleFileDownload}
                          >
                            <BsDownload />
                            <span>Report</span>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {}
          <Row>
            <Col sm={12}>
              <hr className="divider" />
            </Col>
          </Row>
          {title === "View" ? (
            <div>
              <Row>
                <Col sm={12}>
                  <SurveyDetails
                    surveyName={surveyName}
                    questionCount={questions.length}
                    questions={questions}
                    answerCount={answerCount}
                    surveyListData={listData}
                    surveyMember={surveyMember}
                  ></SurveyDetails>
                </Col>
              </Row>
              <Row className="eventDate">
                <Col lg="6">
                  <InputDate
                    label="Start Date"
                    id="events_date"
                    placeholder="Select Date"
                    dateFormat="MM/dd/yyyy"
                    disableDay={(date) => date < new Date()}
                    includeTime={false}
                    initialDate={startDate}
                    disabled={title === "View" ? true : false}
                    onChange={(date) => setStartDate(date.$d)}
                    showToday={false}
                  />
                </Col>
                <Col lg="6">
                  <InputDate
                    label="End Date"
                    id="events_date"
                    placeholder="Select Date"
                    dateFormat="MM/dd/yyyy"
                    disableDay={(date) => date < new Date()}
                    includeTime={false}
                    initialDate={endDate}
                    disabled={title === "View" ? true : false}
                    onChange={(date) => setEndDate(date.$d)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <hr className="divider" />
                </Col>
              </Row>
            </div>
          ) : null}
          <Row>
            <Col sm={12}>
              <Form>
                <Form.Group controlId="surveyName">
                  {title !== "View" ? (
                    <div className="surveyNam">
                      <p className="surveyNam-label">Survey Name *</p>
                      <Form.Control
                        type="text"
                        required={true}
                        placeholder="Enter Survey Name"
                        value={surveyName}
                        disabled={title === "View"}
                        onChange={(e) => {
                          if (e.target.value.length <= 100) {
                            setSurveyName(e.target.value);
                          }
                        }}
                        // style={{ width: "60%" }}
                      />
                    </div>
                  ) : null}
                </Form.Group>
                {title === "View" ? null : (
                  <>
                    <Row className="eventDate">
                      <Col lg="6">
                        <InputDate
                          label="Start Date *"
                          id="events_start_date"
                          placeholder="Select Date"
                          dateFormat="MM/dd/yyyy" // Adjust to match react-datepicker's format
                          disableDay={new Date()} // Disable past dates
                          includeTime={false}
                          initialDate={startDate} // startDate should be a Date object
                          onChange={(date) => setStartDate(date)} // No need for date.$d, just pass date
                        />
                      </Col>
                      <Col lg="6">
                        <InputDate
                          label="End Date *"
                          id="events_date"
                          placeholder="Select Date"
                          dateFormat="MM/dd/yyyy"
                          disableDay={new Date()}
                          includeTime={false}
                          initialDate={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {validationForm ? (
                          <p style={{ color: "red", marginTop: "10px" }}>
                            Fields with * mark are mandatory.
                          </p>
                        ) : null}
                        {dateError && dateError.length ? (
                          <p style={{ color: "red", marginTop: "10px" }}>
                            {dateError}
                          </p>
                        ) : null}
                        <hr className="divider" />
                        {questionLengthValidation ? (
                          <p style={{ color: "red", marginTop: "10px" }}>
                            Please add atleast one question to proceed
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="questions">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {questions.length > 0 &&
                          questions.map((question, index) => {
                            // if (question?.isActive === true) {
                            const isExistingQuestion = !!question._id;
                            return (
                              <Draggable
                                key={index}
                                draggableId={question.id.toString()}
                                index={index}
                                isDragDisabled={title === "View" ? true : false}
                              >
                                {(provided) => (
                                  <div
                                    className="question-box-container"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <div className="dragQuestionNumber">
                                      <div {...provided.dragHandleProps}>
                                        <FaGripVertical className="drag-icon" />
                                      </div>
                                      <span>{index + 1}</span>
                                    </div>
                                    <div
                                      className={`question-box ${
                                        expandedQuestionId === question.id
                                          ? "expanded"
                                          : "collapsed"
                                      }`}
                                    >
                                      <div
                                        className={`question-header ${
                                          errorMessagesOfQuestion.includes(
                                            question.id
                                          )
                                            ? "showError"
                                            : ""
                                        }`}
                                      >
                                        {expandedQuestionId === question.id ? (
                                          <>
                                            <h5>Question</h5>
                                            <div className="drodownTrashSurvey">
                                              {title !== "View" && (
                                                <FiTrash
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    handleDeleteClick(
                                                      question.id
                                                    )
                                                  }
                                                />
                                              )}
                                              <IoIosArrowUp
                                                className="collapsed-arrow"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  toggleQuestion(question.id)
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="collapsedQuestionSummary">
                                              <div className="collapsedQuestFirst">
                                                <span>Question</span>
                                                <div>
                                                  <MdKeyboardArrowRight />
                                                </div>
                                                <span>
                                                  {question.question_type ===
                                                    "radio" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Radio Button
                                                      </span>

                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <MdRadioButtonChecked
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {question.question_type ===
                                                    "checkbox" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Multiple Choice
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <IoIosCheckboxOutline
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {question.question_type ===
                                                    "slider_group" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Slider
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <RxSlider
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {question.question_type ===
                                                    "text" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Input Box
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <BsTextareaResize
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {question.question_type ===
                                                    "tab" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Tab Question
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <MdRadioButtonChecked
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {question.question_type ===
                                                    "calendar" && (
                                                    <div className="question_type_main">
                                                      <span className="question_type">
                                                        Date Type
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: "8px",
                                                        }}
                                                      >
                                                        <SlCalender
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                </span>
                                                <h5
                                                  style={{
                                                    wordBreak: "break-all",
                                                  }}
                                                >
                                                  {question.questionText}
                                                </h5>
                                              </div>
                                              <div>
                                                <IoIosArrowDown
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    toggleQuestion(question.id)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      {expandedQuestionId === question.id && (
                                        <div className="question-content">
                                          <Form.Group
                                            controlId={`questionText${question.id}`}
                                            style={{ position: "relative" }}
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Enter Question"
                                              required={true}
                                              value={question.questionText}
                                              disabled={
                                                title === "View" ? true : false
                                              }
                                              onChange={(e) =>
                                                handleInputChange(question, e)
                                              }
                                              isInvalid={false}
                                            />
                                            {showDropdown && (
                                              <ListGroup
                                                style={{
                                                  position: "absolute",
                                                  top: "100%",
                                                  zIndex: 1000,
                                                  width: "100%",
                                                  maxHeight: "200px",
                                                  overflowY: "auto",
                                                  border: "1px solid #ddd",
                                                  borderRadius: "4px",
                                                  backgroundColor: "#fff",
                                                  boxShadow:
                                                    "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                }}
                                                ref={dropdownRef}
                                              >
                                                {dropdownError && (
                                                  <ListGroup.Item
                                                    style={{ color: "red" }}
                                                  >
                                                    {dropdownError}
                                                  </ListGroup.Item>
                                                )}
                                                {filteredQuestions.map(
                                                  (q, index) => (
                                                    <ListGroup.Item
                                                      key={index}
                                                      action
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSelectQuestion(
                                                          q,
                                                          question
                                                        );
                                                      }}
                                                      style={{
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                        borderBottom:
                                                          "1px solid #ddd",
                                                      }}
                                                    >
                                                      {q}
                                                    </ListGroup.Item>
                                                  )
                                                )}
                                              </ListGroup>
                                            )}
                                            {errorMessagesOfQuestion.includes(
                                              question.id
                                            ) &&
                                            question.questionText === "" ? (
                                              <p className="errorMessageQuestion">
                                                Please fill out this field.
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </Form.Group>
                                          <Form.Group
                                            controlId={`question_type${question.id}`}
                                          >
                                            <div className="questionsSelect">
                                              <div className="selectQuestions">
                                                <Form.Label>
                                                  Select Question Type:
                                                </Form.Label>
                                              </div>
                                              <div>
                                                {[
                                                  {
                                                    label: "Radio Button",
                                                    value: "radio",
                                                  },
                                                  {
                                                    label: "Multiple Choice",
                                                    value: "checkbox",
                                                  },
                                                  {
                                                    label: "Input Box",
                                                    value: "text",
                                                  },
                                                  {
                                                    label: "Slider",
                                                    value: "slider_group",
                                                  },
                                                  {
                                                    label: "Tab Question",
                                                    value: "tab",
                                                  },
                                                  {
                                                    label: "Date Type",
                                                    value: "calendar",
                                                  },
                                                ].map((type, index) => (
                                                  <Form.Check
                                                    key={type.label}
                                                    inline
                                                    label={type.label}
                                                    type="radio"
                                                    name={`questionType${index}`}
                                                    id={`questionType${index}`}
                                                    checked={
                                                      question.question_type ===
                                                      type.value
                                                    }
                                                    disabled={
                                                      title === "View"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() =>
                                                      handleQuestionTypeChange(
                                                        question.id,
                                                        type.value
                                                      )
                                                    }
                                                  />
                                                ))}
                                              </div>
                                            </div>
                                          </Form.Group>
                                          {question.question_type && (
                                            <>
                                              <div className="question-options">
                                                <div className="questionSwitches">
                                                  <div>
                                                    <CustomSwitch
                                                      id={`mandatory${question.id}`}
                                                      name={`questionType${question.id}`}
                                                      checked={
                                                        question.isMandatory
                                                      }
                                                      disabled={
                                                        title === "View"
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        handleQuestionChange(
                                                          question.id,
                                                          "isMandatory",
                                                          e.target.checked
                                                        )
                                                      }
                                                      label="Is the Question Mandatory to Answer?"
                                                    />
                                                  </div>
                                                  <div>
                                                    <CustomSwitch
                                                      id={`active${question.id}`}
                                                      checked={
                                                        question.isActive
                                                      }
                                                      disabled={
                                                        title === "View"
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        handleQuestionChange(
                                                          question.id,
                                                          "isActive",
                                                          e.target.checked
                                                        )
                                                      }
                                                      label="Question Active"
                                                    />
                                                  </div>
                                                </div>
                                                {["radio", "checkbox"].includes(
                                                  question.question_type
                                                ) && (
                                                  <>
                                                    <h6
                                                      style={{
                                                        marginTop: "15px",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      Options:
                                                    </h6>
                                                    <div className="options">
                                                      {question.options
                                                        .filter(
                                                          (option) =>
                                                            option.is_active !==
                                                            false
                                                        )
                                                        .map(
                                                          (option, oIndex) => {
                                                            radioOrMultichoiceQuestionOptions =
                                                              option.option_text
                                                                ? option.option_text
                                                                : "";
                                                            const isError =
                                                              errorMessagesOfQuestion.includes(
                                                                question.id
                                                              );
                                                            const actualIndex =
                                                              question.options.indexOf(
                                                                option
                                                              );
                                                            return (
                                                              <div
                                                                key={oIndex}
                                                                className="option"
                                                              >
                                                                <span className="option-label">
                                                                  Option{" "}
                                                                  {oIndex + 1}:
                                                                </span>
                                                                <Form.Control
                                                                  type="text"
                                                                  placeholder="Please Enter an Option"
                                                                  value={
                                                                    option.option_text
                                                                  }
                                                                  disabled={
                                                                    title ===
                                                                    "View"
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    if (
                                                                      e.target
                                                                        .value
                                                                        .length <=
                                                                      50
                                                                    ) {
                                                                      handleOptionChange(
                                                                        question.id,
                                                                        "option_text",
                                                                        actualIndex,
                                                                        e.target
                                                                          .value
                                                                      );
                                                                    }
                                                                  }}
                                                                  style={{
                                                                    ...(oIndex ===
                                                                      1 &&
                                                                    title !==
                                                                      "View"
                                                                      ? {
                                                                          marginRight:
                                                                            "26px",
                                                                        }
                                                                      : {}),
                                                                    borderColor:
                                                                      isError &&
                                                                      !option.option_text
                                                                        ? "red"
                                                                        : "",
                                                                  }}
                                                                />
                                                                {title !==
                                                                  "View" &&
                                                                  oIndex >
                                                                    1 && (
                                                                    <IoClose
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleRemoveOption(
                                                                          question.id,
                                                                          actualIndex
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </div>
                                                    {title !== "View" && (
                                                      <Button
                                                        variant="link"
                                                        onClick={() =>
                                                          handleAddOption(
                                                            question.id
                                                          )
                                                        }
                                                        className="add-option-button"
                                                      >
                                                        <CiCirclePlus
                                                          style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                          }}
                                                        />{" "}
                                                        Add Option
                                                      </Button>
                                                    )}
                                                    {errorMessagesOfQuestion.includes(
                                                      question.id
                                                    ) &&
                                                    question.options.some(
                                                      (option) =>
                                                        option.option_text ===
                                                        ""
                                                    ) ? (
                                                      <p className="errorMessageOptions">
                                                        Some fields have errors.
                                                        Please correct them and
                                                        try again.
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )}
                                                {question.question_type ===
                                                  "text" && (
                                                  <>
                                                    <div className="questionSwitches">
                                                      <div>
                                                        <CustomSwitch
                                                          id={`numericOnly${question.id}`}
                                                          checked={
                                                            question.numericOnly
                                                          }
                                                          onChange={(e) =>
                                                            handleQuestionChange(
                                                              question.id,
                                                              "numericOnly",
                                                              e.target.checked
                                                            )
                                                          }
                                                          label="Is the Answer Numeric Only?"
                                                        />
                                                      </div>
                                                    </div>

                                                    <div
                                                      style={{
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      <Form.Group
                                                        controlId={`placeholderText${question.id}`}
                                                      >
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Enter Placeholder Text (Optional)"
                                                          value={
                                                            question.placeholderText
                                                          }
                                                          disabled={
                                                            title === "View"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.value
                                                                .length <= 50
                                                            ) {
                                                              handleQuestionChange(
                                                                question.id,
                                                                "placeholderText",
                                                                e.target.value
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </Form.Group>
                                                    </div>

                                                    <ReactQuill
                                                      theme="snow"
                                                      value={question.note}
                                                      readOnly={
                                                        title === "View"
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(value) => {
                                                        if (
                                                          value.length <= 100
                                                        ) {
                                                          handleQuestionChange(
                                                            question.id,
                                                            "note",
                                                            value
                                                          );
                                                        }
                                                      }}
                                                      placeholder="Enter Note (Optional)"
                                                    />
                                                  </>
                                                )}
                                                {question.question_type ===
                                                  "slider_group" && (
                                                  <>
                                                    <div
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      <Form.Group
                                                        controlId={`note${question.id}`}
                                                      >
                                                        <ReactQuill
                                                          theme="snow"
                                                          value={question.note}
                                                          readOnly={
                                                            title === "View"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(value) => {
                                                            if (
                                                              value.length <=
                                                              100
                                                            ) {
                                                              handleQuestionChange(
                                                                question.id,
                                                                "note",
                                                                value
                                                              );
                                                            }
                                                          }}
                                                          placeholder="Enter Note (Optional)"
                                                        />
                                                      </Form.Group>
                                                    </div>
                                                    <h5
                                                      style={{
                                                        marginTop: "15px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Sub Questions:
                                                    </h5>
                                                    {question.options
                                                      .filter(
                                                        (option) =>
                                                          option.is_active !==
                                                          false
                                                      )
                                                      .map(
                                                        (
                                                          option,
                                                          subQuestionIndex
                                                        ) => {
                                                          sliderQuestionOptions.questionTitle =
                                                            option.question_title
                                                              ? option.question_title
                                                              : "";
                                                          sliderQuestionOptions.minValue =
                                                            option.min_value
                                                              ? option.min_value
                                                              : "";
                                                          sliderQuestionOptions.maxValue =
                                                            option.max_value
                                                              ? option.max_value
                                                              : "";
                                                          sliderQuestionOptions.leftLabel =
                                                            option.min_text
                                                              ? option.min_text
                                                              : "";
                                                          sliderQuestionOptions.rightLabel =
                                                            option.max_text
                                                              ? option.max_text
                                                              : "";

                                                          const isError =
                                                            errorMessagesOfQuestion.includes(
                                                              question.id
                                                            );
                                                          const actualIndex =
                                                            question.options.indexOf(
                                                              option
                                                            );
                                                          return (
                                                            <div
                                                              key={
                                                                subQuestionIndex
                                                              }
                                                              className="sub-question"
                                                            >
                                                              <div className="sub-question-header">
                                                                <h6
                                                                  style={{
                                                                    fontWeight:
                                                                      "normal",
                                                                  }}
                                                                >
                                                                  Sub Question{" "}
                                                                  {subQuestionIndex +
                                                                    1}
                                                                  :
                                                                </h6>
                                                                {subQuestionIndex >
                                                                  0 &&
                                                                  title !==
                                                                    "View" && (
                                                                    <IoClose
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleRemoveSubQuestion(
                                                                          question.id,
                                                                          actualIndex
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                              </div>
                                                              <div className="sliderFirstRow">
                                                                <div className="sliderQuestion">
                                                                  <Form.Group
                                                                    controlId={`subQuestionText${question.id}${subQuestionIndex}`}
                                                                  >
                                                                    <Form.Control
                                                                      type="text"
                                                                      placeholder="Enter Slider Question"
                                                                      value={
                                                                        option.question_title
                                                                      }
                                                                      disabled={
                                                                        title ===
                                                                        "View"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        if (
                                                                          e
                                                                            .target
                                                                            .value
                                                                            .length <=
                                                                          100
                                                                        ) {
                                                                          handleSubQuestionChange(
                                                                            question.id,
                                                                            actualIndex,
                                                                            "question_title",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }
                                                                      }}
                                                                      style={{
                                                                        borderColor:
                                                                          isError &&
                                                                          !option.question_title
                                                                            ? "red"
                                                                            : "",
                                                                      }}
                                                                    />
                                                                  </Form.Group>
                                                                </div>
                                                                <Form.Group
                                                                  controlId={`minValue${question.id}${subQuestionIndex}`}
                                                                >
                                                                  <Form.Control
                                                                    type="number"
                                                                    placeholder="Select Minimum Value"
                                                                    value={
                                                                      option.min_value
                                                                    }
                                                                    disabled={
                                                                      title ===
                                                                      "View"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleSubQuestionChange(
                                                                        question.id,
                                                                        actualIndex,
                                                                        "min_value",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    style={{
                                                                      borderColor:
                                                                        isError &&
                                                                        (!option.min_value ||
                                                                          option.min_value >=
                                                                            option.max_value)
                                                                          ? "red"
                                                                          : "",
                                                                    }}
                                                                  />
                                                                </Form.Group>
                                                                <Form.Group
                                                                  controlId={`maxValue${question.id}${subQuestionIndex}`}
                                                                >
                                                                  <Form.Control
                                                                    type="number"
                                                                    placeholder="Select Maximum Value"
                                                                    value={
                                                                      option.max_value ??
                                                                      ""
                                                                    }
                                                                    disabled={
                                                                      title ===
                                                                      "View"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleSubQuestionChange(
                                                                        question.id,
                                                                        actualIndex,
                                                                        "max_value",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    style={{
                                                                      borderColor:
                                                                        isError &&
                                                                        (!option.max_value ||
                                                                          option.max_value <=
                                                                            option.min_value)
                                                                          ? "red"
                                                                          : "",
                                                                    }}
                                                                  />
                                                                </Form.Group>
                                                              </div>
                                                              <Row>
                                                                <Col md={6}>
                                                                  <Form.Group
                                                                    controlId={`leftLabel${question.id}${subQuestionIndex}`}
                                                                  >
                                                                    <Form.Control
                                                                      type="text"
                                                                      placeholder="Left Label"
                                                                      value={
                                                                        option.min_text
                                                                      }
                                                                      disabled={
                                                                        title ===
                                                                        "View"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        if (
                                                                          e
                                                                            .target
                                                                            .value
                                                                            .length <=
                                                                          50
                                                                        ) {
                                                                          handleSubQuestionChange(
                                                                            question.id,
                                                                            actualIndex,
                                                                            "min_text",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }
                                                                      }}
                                                                      style={{
                                                                        borderColor:
                                                                          isError &&
                                                                          (!option.min_text ||
                                                                            option.min_text ===
                                                                              option.max_text)
                                                                            ? "red"
                                                                            : "",
                                                                      }}
                                                                    />
                                                                  </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                  <Form.Group
                                                                    controlId={`rightLabel${question.id}${subQuestionIndex}`}
                                                                  >
                                                                    <Form.Control
                                                                      type="text"
                                                                      placeholder="Right Label"
                                                                      value={
                                                                        option.max_text
                                                                      }
                                                                      disabled={
                                                                        title ===
                                                                        "View"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        if (
                                                                          e
                                                                            .target
                                                                            .value
                                                                            .length <=
                                                                          50
                                                                        ) {
                                                                          handleSubQuestionChange(
                                                                            question.id,
                                                                            actualIndex,
                                                                            "max_text",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }
                                                                      }}
                                                                      style={{
                                                                        borderColor:
                                                                          isError &&
                                                                          (!option.max_text ||
                                                                            option.min_text ===
                                                                              option.max_text)
                                                                            ? "red"
                                                                            : "",
                                                                      }}
                                                                    />
                                                                  </Form.Group>
                                                                </Col>
                                                              </Row>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    {title !== "View" && (
                                                      <Button
                                                        variant="link"
                                                        onClick={() =>
                                                          handleAddSubQuestion(
                                                            question.id
                                                          )
                                                        }
                                                        className="add-option-button"
                                                      >
                                                        <CiCirclePlus
                                                          style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                          }}
                                                        />{" "}
                                                        Add Slider
                                                      </Button>
                                                    )}
                                                    {errorMessagesOfQuestion.includes(
                                                      question.id
                                                    ) ? (
                                                      sliderQuestionOptions.questionTitle ===
                                                        "" ||
                                                      sliderQuestionOptions.minValue ===
                                                        "" ||
                                                      sliderQuestionOptions.maxValue ===
                                                        "" ||
                                                      sliderQuestionOptions.leftLabel ===
                                                        "" ||
                                                      sliderQuestionOptions.rightLabel ===
                                                        "" ? (
                                                        <p className="errorMessageOptions">
                                                          Some fields have
                                                          errors. Please correct
                                                          them and try again.
                                                        </p>
                                                      ) : sliderQuestionOptions.minValue >=
                                                        sliderQuestionOptions.maxValue ? (
                                                        <p className="errorMessageOptions">
                                                          Minimum value should
                                                          be less than Maximum
                                                          value.
                                                        </p>
                                                      ) : sliderQuestionOptions.leftLabel ===
                                                        sliderQuestionOptions.rightLabel ? (
                                                        <p className="errorMessageOptions">
                                                          Left label and Right
                                                          label cannot be the
                                                          same.
                                                        </p>
                                                      ) : (
                                                        ""
                                                      )
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )}
                                                {question.question_type ===
                                                  "tab" && (
                                                  <>
                                                    <div
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      <Form.Group
                                                        controlId={`note${question.id}`}
                                                      >
                                                        <ReactQuill
                                                          theme="snow"
                                                          value={question.note}
                                                          readOnly={
                                                            title === "View"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(value) => {
                                                            console.log(
                                                              "value.length",
                                                              value,
                                                              value.length
                                                            );
                                                            if (
                                                              value.length <=
                                                              100
                                                            ) {
                                                              handleQuestionChange(
                                                                question.id,
                                                                "note",
                                                                value
                                                              );
                                                            }
                                                          }}
                                                          placeholder="Enter Note (Optional)"
                                                        />
                                                      </Form.Group>
                                                    </div>
                                                    <h6
                                                      style={{
                                                        marginTop: "15px",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      Options:
                                                    </h6>
                                                    <div className="options">
                                                      {question.options.map(
                                                        (option, oIndex) => {
                                                          tabQuestionOptions =
                                                            option.option_text
                                                              ? option.option_text
                                                              : "";
                                                          const isError =
                                                            errorMessagesOfQuestion.includes(
                                                              question.id
                                                            );
                                                          const actualIndex =
                                                            question.options.indexOf(
                                                              option
                                                            );
                                                          return (
                                                            <div
                                                              key={oIndex}
                                                              className="option"
                                                            >
                                                              <span className="option-label">
                                                                Option{" "}
                                                                {oIndex + 1}:
                                                              </span>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder="Please Enter an Option"
                                                                value={
                                                                  option.option_text ===
                                                                  "happy"
                                                                    ? "Happy"
                                                                    : "Sad"
                                                                }
                                                                disabled
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .value
                                                                      .length <=
                                                                    50
                                                                  ) {
                                                                    handleOptionChange(
                                                                      question.id,
                                                                      "option_text",
                                                                      actualIndex,
                                                                      e.target
                                                                        .value ||
                                                                        option.option_text
                                                                    );
                                                                  }
                                                                }}
                                                                style={{
                                                                  ...(oIndex ===
                                                                    1 &&
                                                                  title !==
                                                                    "View"
                                                                    ? {
                                                                        marginRight:
                                                                          "26px",
                                                                      }
                                                                    : {}),
                                                                  borderColor:
                                                                    isError &&
                                                                    !option.option_text
                                                                      ? "red"
                                                                      : "",
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>

                                                    {errorMessagesOfQuestion.includes(
                                                      question.id
                                                    ) &&
                                                    tabQuestionOptions ===
                                                      "" ? (
                                                      <p className="errorMessageOptions">
                                                        Some fields have errors.
                                                        Please correct them and
                                                        try again.
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )}
                                                {question.question_type ===
                                                  "calendar" && (
                                                  <>
                                                    <div
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      <ReactQuill
                                                        theme="snow"
                                                        value={question.note}
                                                        readOnly={
                                                          title === "View"
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(value) => {
                                                          if (
                                                            value.length <= 100
                                                          ) {
                                                            handleQuestionChange(
                                                              question.id,
                                                              "note",
                                                              value
                                                            );
                                                          }
                                                        }}
                                                        placeholder="Enter Note (Optional)"
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              {title === "Edit" &&
                                                isExistingQuestion && (
                                                  <div className="editBtn-container">
                                                    <hr className="editBtn-container-hrLine" />
                                                    <div className="editBtn-container-btn">
                                                      <Button
                                                        className="editBtn-container-btn-cancel"
                                                        onClick={() =>
                                                          toggleQuestion(
                                                            question.id,
                                                            true
                                                          )
                                                        }
                                                      >
                                                        Cancel
                                                      </Button>
                                                      <Button
                                                        className="editBtn-container-btn-update"
                                                        onClick={() => {
                                                          updateQuestionsById(
                                                            question.id,
                                                            setErrorMessagesOfQuestion,
                                                            toggleQuestion
                                                          );
                                                          // toggleQuestion(
                                                          //   question.id
                                                          // );
                                                        }}
                                                      >
                                                        Update
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                            // }
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Button
                  variant="outline-primary"
                  className="add-question-button"
                  onClick={async () => {
                    handleAddQuestion();
                  }}
                  disabled={title === "View" ? true : false}
                >
                  <CiCirclePlus
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  />{" "}
                  Add Question
                </Button>
              </Form>
            </Col>
          </Row>
          {title !== "View" ? (
            <div className="footerSurveyButtons">
              <Button
                variant="outline-primary"
                onClick={handleSaveAsDraft}
                disabled={
                  surveyName === "" ||
                  startDate === null ||
                  endDate === null ||
                  !questions.length > 0
                }
              >
                Save as a Draft
              </Button>
              <Button
                className="save-proceed-button"
                onClick={handleSaveAndProceed}
                disabled={
                  surveyName === "" ||
                  startDate === null ||
                  endDate === null ||
                  !questions.length > 0
                }
                // disabled={validationForm}
              >
                Save and Proceed to Next Step
              </Button>
            </div>
          ) : null}
        </div>
      </Container>
      {showPopup && (
        <CommonPopup
          title={
            popupAction === "delete" ? "Delete Question?" : "Unsaved Changes"
          }
          mainText={
            popupAction === "delete"
              ? "Are you sure you want to delete this question? This action cannot be undone."
              : mainText
          }
          btnTitle={popupAction === "delete" ? "Delete" : btnTitle}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
        />
      )}
    </div>
  );
};

export default CreateSurvey;

import React from "react";
import { PiPencilSimpleLineLight, PiUserCircleGearLight } from "react-icons/pi";
import "./OptionsCard.scss";

const OptionsCard = ({ onEdit, onDisable, onEnable, isActive }) => {
  return (
    <div className="options-card">
      <button onClick={onEdit}> <PiPencilSimpleLineLight/>  Edit</button>
      {isActive ? (
        <button onClick={onDisable}> <PiUserCircleGearLight/> Disable</button>
      ) : (
        <button onClick={onEnable}> <PiUserCircleGearLight/> Enable</button>
      )}
    </div>
  );
};

export default OptionsCard;

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import './Toastr.scss'

toastr.options = {
  positionClass:
    window.innerWidth <= 768 ? "toast-bottom-full-width" : "toast-top-right",
  hideDuration: 100,
  timeOut: 3000,
};

export default toastr;
import React, { useEffect, useRef, useState } from "react";
import "./DropdownCheckbox.scss";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";

const DropdownCheckbox = ({
  title,
  options,
  onSelectionChange,
  error,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    // Filter out any invalid IDs from selectedOptions
    const validSelectedOptions =
      value?.filter((id) => options.some((option) => option.id === id)) || [];
    setSelectedOptions(validSelectedOptions);
  }, [value, options]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (id) => {
    const newSelectedOptions = selectedOptions.includes(id)
      ? selectedOptions.filter((item) => item !== id)
      : [...selectedOptions, id];

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions); // Notify parent component
  };

  const removeOption = (id) => {
    const newSelectedOptions = selectedOptions.filter((item) => item !== id);
    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions); // Re-validate on remove
  };

  const getOptionNameById = (id) => {
    const option = options?.find((option) => option.id === id);
    return option ? option.name : null;
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdownCheckbox" ref={dropdownRef}>
      <div className={`checkboxWrapper ${error ? "inputError" : ""}`}>
        <button
          type="button"
          onClick={toggleDropdown}
          className="dropdownButton"
        >
          {title}{" "}
          {selectedOptions.length > 0 ? `(${selectedOptions.length})` : null}
          <IoIosArrowDown className="dropdownIcon" />
        </button>
        {isOpen && (
          <div className="dropdownContent">
            {options.map((option) => (
              <label key={option.id} className="checkboxContainer">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleCheckboxChange(option.id)}
                />
                <span>{option.name}</span>
              </label>
            ))}
          </div>
        )}
        {selectedOptions.length > 0 && (
          <div className="selectedSummary">
            {selectedOptions.map((id) => {
              const optionName = getOptionNameById(id);
              return optionName ? (
                <div key={id} className="selectedOption">
                  {optionName}
                  <span
                    className="removeOption"
                    onClick={() => removeOption(id)}
                  >
                    ×
                  </span>
                </div>
              ) : null;
            })}
          </div>
        )}
        {error && (
          <div className="error">
            <FiAlertCircle /> {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownCheckbox;

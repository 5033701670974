import axios from "axios";
import { URL, ENVIRONMENT } from "../../config/constants";

// Log environment and URL in non-production environments
if (ENVIRONMENT !== "PRODUCTION") {
  console.log("Linked in USER:", ENVIRONMENT);
  console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const resetPasswordApi = (props) => {
  return axios({
    method: "POST",
    url: `${URL["BASE_URL_" + ENVIRONMENT]}/user/change-password`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    validateStatus: false,
    data: JSON.stringify(props),
  });
};

export default resetPasswordApi;

export const URL = {
    // DEVELOPMENT URL (development)
    // BASE_URL_DEVELOPMENT: "http://192.168.0.102:8000/api/v1",
    BASE_URL_DEVELOPMENT: "https://grittywork-pilot.infojiniconsulting.com/api/v1",

    // STAGING URL (staging)
    BASE_URL_STAGING: "https://grittywork-pilot.infojiniconsulting.com/api/v1",

    // PRODUCTION URL (production)
    BASE_URL_PRODUCTION: "https://grittywork.infojiniconsulting.com/api/v1",
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const RECAPTCHA_SITE_KEY = '6LcxleopAAAAAM0CxGIiJRRLfKEAt39wv5X0p14Z';
import { SHOW_TOAST, CLEAR_TOAST } from "../actions/toastActions";

const initialState = {
  show: false,
  message: "",
  variant: "primary",
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        variant: action.payload.variant,
      };
    case CLEAR_TOAST:
      return initialState;
    default:
      return state;
  }
};

export default toastReducer;

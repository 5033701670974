import React, { useEffect, useState } from "react";
import "./signUp.scss";
import logoImg from "../../assets/images/GrittyWork_logo_print__1_-removebg-preview 1.png";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import VerifyModal from "./VerifyModal";
import checks from "../../assets/images/checks.svg";
import DropdownCheckbox from "./DropdownCheckbox";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitSuccess from "./SubmitSuccess";
import { FiAlertCircle } from "react-icons/fi";
import axios from "../../utils/axios/api";
import AppDownload from "./AppDownload";
import CustomDropdown from "../../components/CustomDropdown";
import { RECAPTCHA_SITE_KEY } from "../../utils/config/constants";

const SignUp = () => {
  const [WorkSite, setWorkSite] = useState(false);
  const [Verify, setVerify] = useState(false);
  const [IsVerified, setIsVerified] = useState(false);
  const [Email, setEmail] = useState("");
  const [SuccessSubmit, setSuccessSubmit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [siteError, setSiteError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [VerifyStatus, setVerifyStatus] = useState("");
  const [Review, setReview] = useState(false);
  const [Accepted, setAccepted] = useState(false);

  const [trainingYear, setTrainingYear] = useState("");
  const [trainingYearError, setTrainingYearError] = useState("");

  const [SiteData, setSiteData] = useState(null);
  const [RoleData, setRoleData] = useState(null);
  const [SpecialityData, setSpecialityData] = useState(null);
  const [WorkLocationData, setWorkLocationData] = useState(null);

  const [selectedSite, setSelectedSite] = useState({ id: null, name: null });
  const [selectedRole, setSelectedRole] = useState("");

  const [selectedSpecialtyOptions, setSelectedSpecialtyOptions] = useState([]);
  const [selectedWorkLocationOptions, setSelectedWorkLocationOptions] =
    useState([]);
  const [specialtyError, setSpecialtyError] = useState("");
  const [workLocationError, setWorkLocationError] = useState("");

  const [CaptchaValue, setCaptchaValue] = useState("");
  const [CaptchaError, setCaptchaError] = useState("");

  console.log('selectedSite', selectedSite);
  console.log('selectedRole', selectedRole);
  console.log('selectedSpecialtyOptions', selectedSpecialtyOptions);
  console.log('selectedWorkLocationOptions', selectedWorkLocationOptions);

  const handleSpecialtyChange = (selectedIds) => {
    setSelectedSpecialtyOptions(selectedIds);
    if (selectedIds.length > 0) {
      setSpecialtyError("");
    } else {
      setSpecialtyError("Please select at least one specialty");
    }
  };

  const handleWorkLocationChange = (selectedIds) => {
    setSelectedWorkLocationOptions(selectedIds);
    if (selectedIds.length > 0) {
      setWorkLocationError("");
    } else {
      setWorkLocationError("Please select at least one work location");
    }
  };

  const specialtyOptions = SpecialityData?.map((specialty) => ({
    id: specialty._id,
    name: specialty.specialty_display_name,
  }));

  const locationOptions = WorkLocationData?.map((location) => ({
    id: location._id,
    name: location.work_location_display_name,
  }));

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
      case "lastName":
        if (/[^a-zA-Z\s]/.test(value)) {
          fieldName === "firstName"
            ? setFirstNameError("Only alphabets are allowed")
            : setLastNameError("Only alphabets are allowed");
        } else {
          if (fieldName === "firstName") {
            setFirstName(value);
            setFirstNameError(value.trim() ? "" : "Enter a first name");
          } else {
            setLastName(value);
            setLastNameError(value.trim() ? "" : "Enter a last name");
          }
        }
        break;
      case "email":
        setEmail(value);
        if (!value.trim()) {
          setEmailError("Enter a valid email address");
        } else if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
        ) {
          setEmailError("Enter a valid email address");
        } else {
          setEmailError("");
        }
        break;
      case "site":
        setSelectedSite({ id: value.id, name: value.name });
        setSiteError(value.name ? "" : "Please select a site");
        break;
      case "captcha":
        setCaptchaValue(value);
        setCaptchaError(value ? "" : "Please verify that you are not a robot");
        break;
      case "role":
        setSelectedRole(value.name);
        setRoleError(value ? "" : "Please select a role");
        break;
      case "trainingYear":
        setTrainingYear(value);
        if (!value.trim()) {
          setTrainingYearError("Please enter the year training was completed");
        } else if (/[^0-9]/.test(value)) {
          setTrainingYearError("Only numeric values are allowed");
        } else if (!/^\d{4}$/.test(value)) {
          setTrainingYearError("Enter a valid year");
        } else {
          setTrainingYearError("");
        }
        break;
      default:
        break;
    }
  };

  const validateDropdown = () => {
    let isValid = true;

    if (!selectedSite.name) {
      setSiteError("Please select a site");
      isValid = false;
    } else {
      setSiteError("");
    }

    if (!selectedRole) {
      setRoleError("Please select a role");
      isValid = false;
    } else {
      setRoleError("");
    }

    if (!trainingYear) {
      setTrainingYearError("Please enter year training was completed ");
      isValid = false;
    } else if (/[^0-9]/.test(trainingYear)) {
      setTrainingYearError("Only numeric values are allowed");
    } else if (!/^\d{4}$/.test(trainingYear)) {
      setTrainingYearError("Enter a valid year");
    } else {
      setTrainingYearError("");
    }

    if (!selectedWorkLocationOptions.length) {
      setWorkLocationError("Please select at least one work location");
      isValid = false;
    } else {
      setWorkLocationError("");
    }

    if (!selectedSpecialtyOptions.length) {
      setSpecialtyError("Please select at least one primary specialty");
      isValid = false;
    } else {
      setSpecialtyError("");
    }

    if (!CaptchaValue) {
      setCaptchaError("Please verify that you are not a robot");
      isValid = false;
    } else {
      setCaptchaError("");
    }

    return isValid;
  };

  const validateForm = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError("Enter a first name");
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Enter a last name");
      valid = false;
    } else {
      setLastNameError("");
    }

    if (!Email) {
      setEmailError("Enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    return valid;
  };

  const getWorkSiteDropdownData = async () => {
    try {
      const siteData = await axios.get("user/sites");
      localStorage.setItem("siteData", JSON.stringify(siteData.data.data));
      setSiteData(siteData.data.data);

      const roleData = await axios.get("user/cms/roles");
      localStorage.setItem("roleData", JSON.stringify(roleData.data.data));
      setRoleData(roleData.data.data);

      const specialityData = await axios.get("user/specialty");
      localStorage.setItem(
        "specialityData",
        JSON.stringify(specialityData.data.data)
      );
      setSpecialityData(specialityData.data.data);

      const workLocationData = await axios.get("user/work-location");
      localStorage.setItem(
        "workLocationData",
        JSON.stringify(workLocationData.data.data)
      );
      setWorkLocationData(workLocationData.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm() && IsVerified) {
      setWorkSite(true);
      getWorkSiteDropdownData();
    } else {
      if (Email && !IsVerified) {
        setEmailError("Email must be verified");
      }

      console.log("Validation Failed");
    }
  };

  useEffect(() => {
    if (VerifyStatus === "pending") {
      setReview(true);
    } else {
      setReview(false);
    }

    if (VerifyStatus === "accepted") {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [VerifyStatus]);

  console.log('VerifyStatus', VerifyStatus);

  const handleVerifyEmail = async () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email) && (EmailError === "Email must be verified" || !EmailError)) {
      try {
        const res = await axios.post("user/request-otp", { email: Email });
        console.log(res.data);
  
        if (res.status === 200) {
          setVerify(true);
        }
      } catch (error) {
        if (error.response) {
          console.log("Error data:", error.response.data);
          const userStatus = error.response.data.user.user_status;

          if(userStatus === 'rejected'){
            setEmailError("The email has been rejected");
          }
          setVerifyStatus(userStatus); // Update VerifyStatus here
        } else {
          console.log("Unexpected error:", error);
        }
      }
    } else if (!Email) {
      setEmailError("Enter a valid email address");
    }
  };

  
  

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setSelectedSite({ id: null, name: null });
    setSelectedRole("");
    setSelectedSpecialtyOptions([]);
    setSelectedWorkLocationOptions([]);
    setTrainingYear("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setSiteError("");
    setRoleError("");
    setSpecialtyError("");
    setWorkLocationError("");
    setTrainingYearError("");
    setCaptchaError("");
    setCaptchaValue("");
    setVerifyStatus("");
    setIsVerified(false);
    setVerify(false);
  };

  const resetWorkSiteFields = () => {
    setSelectedSite({ id: null, name: null });
    setSelectedRole("");
    setSelectedSpecialtyOptions([]);
    setSelectedWorkLocationOptions([]);
    setTrainingYear("");
    setSiteError("");
    setRoleError("");
    setSpecialtyError("");
    setWorkLocationError("");
    setTrainingYearError("");
    setCaptchaError("");
    setCaptchaValue("");
  }

  const submitRegistration = async (e) => {
    e.preventDefault();

    const postData = {
      first_name: firstName,
      last_name: lastName,
      email: Email,
      user_type: "local",
      site: selectedSite.id,
      year_of_completed_training: trainingYear,
      selected_role: selectedRole,
      specialty: selectedSpecialtyOptions,
      work_location: selectedWorkLocationOptions,
    };

    try {
      if (validateDropdown()) {
        const res = await axios.post("user/register", postData);

        if (res.status === 200) {
          setSuccessSubmit(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const siteOptions = SiteData?.map((site) => ({
    id: site._id,
    name: site.site_display_name,
  }));

  const roleOptions = RoleData?.map((role) => ({
    id: role._id,
    name: role.role_display_name,
  }));

  return (
    <>
      {SuccessSubmit ? (
        <SubmitSuccess
          setWorkSite={setWorkSite}
          setSuccessSubmit={setSuccessSubmit}
          resetFormFields={resetFormFields} // Pass the resetFormFields function
        />
      ) : Review ? (
        <SubmitSuccess  resetFormFields={resetFormFields} email={Email} setReview={setReview} review={true} />
      ) : Accepted ? (
        <SubmitSuccess
          email={Email}
          setAccepted={setAccepted}
          accepted={true}
          resetFormFields={resetFormFields}
        />
      ) : (
        <div className="signUp">
          <div className="grittyLogo">
            <img src={logoImg} alt="" />
          </div>
          <div className="signUpbox">
            <div className="signUpHeader">
              <h1>Sign Up for Account</h1>
            </div>

            <div className="signUpInfo">
              <p>
                {!WorkSite ? (
                  <>
                    Personal Information <span>(Step 1/2)</span>
                  </>
                ) : (
                  <>
                    Work Site Information <span>(Step 2/2)</span>
                  </>
                )}
              </p>

              <form action="">
                {!WorkSite && (
                  <div className="inputName">
                    <div
                      className={
                        firstNameError
                          ? "inputWrapper inputError"
                          : "inputWrapper"
                      }
                    >
                      <input
                        onChange={(e) =>
                          validateField("firstName", e.target.value)
                        }
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        maxLength="20"
                      />
                      {firstNameError && (
                        <p className="error">
                          <FiAlertCircle /> {firstNameError}
                        </p>
                      )}
                    </div>

                    <div
                      className={
                        lastNameError
                          ? "inputWrapper inputError"
                          : "inputWrapper"
                      }
                    >
                      <input
                        onChange={(e) =>
                          validateField("lastName", e.target.value)
                        }
                        type="text"
                        placeholder="Last name"
                        value={lastName}
                        maxLength="20"
                      />
                      {lastNameError && (
                        <p className="error">
                          <FiAlertCircle /> {lastNameError}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {WorkSite && (
                  <div className="worksiteInfo">
                    <div className="dropdownName">
                      <div
                        className={`dropdownWrapper ${
                          siteError ? "inputError" : ""
                        }`}
                      >
                        <CustomDropdown
                          title="Select site"
                          options={siteOptions}
                          onSelectionChange={(value) => {
                            validateField("site", value);
                            console.log("value", value);
                          }}
                          error={siteError}
                          value={selectedSite.name}
                        />
                      </div>

                      <div
                        className={`dropdownWrapper ${
                          roleError ? "inputError" : ""
                        }`}
                      >
                        <CustomDropdown
                          title="Select role"
                          options={roleOptions}
                          onSelectionChange={(value) => {
                            validateField("role", value);
                            console.log("value", value);
                          }}
                          error={roleError}
                          value={selectedRole}
                        />
                      </div>
                    </div>

                    <div className="dropdownName">
                      <DropdownCheckbox
                        options={specialtyOptions}
                        title="Select primary specialty"
                        onSelectionChange={handleSpecialtyChange}
                        error={specialtyError}
                        value={selectedSpecialtyOptions}
                      />

                      <DropdownCheckbox
                        options={locationOptions}
                        title="Select work location"
                        onSelectionChange={handleWorkLocationChange}
                        error={workLocationError}
                        value={selectedWorkLocationOptions}
                      />
                    </div>

                    <div className="inputTraining">
                      <div
                        className={`inputWrapper ${
                          trainingYearError ? "inputError" : ""
                        }`}
                      >
                        <input
                          type="text"
                          value={trainingYear}
                          onChange={(e) =>
                            validateField("trainingYear", e.target.value)
                          }
                          placeholder="Enter year"
                        />
                        {trainingYearError && (
                          <p className="error">
                            <FiAlertCircle /> {trainingYearError}
                          </p>
                        )}
                      </div>

                      <div className="inputTrainingLine">
                        <span style={{fontSize: '14px', marginTop: '8px', color: 'gray'}}>Enter year training was completed or expected year</span>
                        <p>(Eg.: Finished Residency in 2010, Enter 2010)</p>
                      </div>
                    </div>

                    <div className="captcha">
                      <ReCAPTCHA
                        className={CaptchaError && "captchaError"}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={(value) => validateField("captcha", value)}
                      />
                      {CaptchaError && (
                        <p className="error">
                          <FiAlertCircle /> {CaptchaError}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {!WorkSite && (
                  <div
                    className={
                      !IsVerified
                        ? "inputEmail notVerified"
                        : "inputEmail verified"
                    }
                  >
                    <div
                      className={
                        EmailError ? "inputWrapper inputError" : "inputWrapper"
                      }
                    >
                      <input
                        value={Email}
                        onChange={(e) => validateField("email", e.target.value)}
                        type="email"
                        placeholder="Work email address"
                        disabled={IsVerified}
                      />
                      {EmailError && (
                        <p className="error">
                          <FiAlertCircle /> {EmailError}
                        </p>
                      )}
                    </div>
                    {!IsVerified ? (
                      <p onClick={handleVerifyEmail}>Verify</p>
                    ) : (
                      <>
                        <span>Email Verified</span>{" "}
                        <img className="checkmark" src={checks} alt="" />
                      </>
                    )}
                  </div>
                )}

                <div className="signUpSubmit">
                  <p>
                    By creating an account, I accept the{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://grittywork.org/privacy-policy/",
                          "_blank"
                        )
                      }
                    >
                      Terms and Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://grittywork.org/privacy-policy/",
                          "_blank"
                        )
                      }
                    >
                      Privacy Policy
                    </span>
                  </p>

                  {!WorkSite ? (
                    <button onClick={handleSubmit}>
                      Proceed to the Work Site Information <GoArrowRight />
                    </button>
                  ) : (
                    <div className="worksiteBtn">
                      <button onClick={() => {
                        setWorkSite(false)
                        resetWorkSiteFields()
                      }}>
                        <GoArrowLeft /> Previous
                      </button>

                      <button onClick={submitRegistration}>Sign Up</button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

          <AppDownload />

          {Verify && (
            <VerifyModal
              setIsVerified={setIsVerified}
              setVerify={setVerify}
              email={Email}
              setEmailError={setEmailError}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SignUp;
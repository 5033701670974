import axios from "axios";
import { getSessionToken } from "../Authentication";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in DELETE_NOTIFICATION:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const deleteNotificationAPI = (_id) => {
    return axios({
        method: "DELETE",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/notifications/delete?id=${_id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
    });
};

export default deleteNotificationAPI;
